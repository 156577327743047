<template>
  <v-flex class="elevation-1 text-sm-center">
    <v-layout row wrap justify-center align-center id="section-dimensions">
      <v-flex xs12 sm12 md8 class="text-sm-left" my-2 px-5>
        <h2 class="headline mx-2">
          <strong class="primary--text">Yeeko Inteligencia Colectiva</strong> es una
          <strong class="primary--text">empresa social</strong>
          especializada en la creación de tecnologías para resolver
          <strong class="primary--text">problemáticas colectivas</strong>
        </h2>
      </v-flex>
      <v-flex xs12 sm12 md3>
        <v-btn outlined disabled>Nuestro Equipo</v-btn>
        <br />
        <v-btn 
          color="primary"
          outlined
          depressed
          @click="show_services = !show_services">
          Servicios Personalizados
          <v-icon>
            {{show_services ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}
          </v-icon>
        </v-btn>
      </v-flex>
      <v-slide-y-transition>
        <v-flex class="text-sm-left" xs12 md10 px-3 v-if="show_services">
          <h2>Podemos ampliar y personalizar los siguientes servicios:</h2>
          <ul class="services py-2 fa-ul">
            <li
              v-html="text" 
              v-for="(text, index) in services_texts" 
              :key="index"
            ></li>
          </ul>
        </v-flex>
      </v-slide-y-transition>
      <v-slide-y-transition>
        <v-flex xs12 mb-3 v-if="show_services">
          <v-btn disabled color="primary" outlined>Solicita cotización</v-btn>
          <br />
          <v-btn disabled outlined>Contacto</v-btn>
        </v-flex>
      </v-slide-y-transition>
    </v-layout>
  </v-flex>
  
</template>

<script>
export default {
  name: "AboutYeeko",
  data() {
    return {
      show_services: false,
      services_texts: [
        `<span class="fa-li primary--text"><i class="fas fa-arrow-right"></i></span>
          Implementación de <b class="primary--text">Metodologías</b> online-offline de participación`,
        `<span class="fa-li primary--text"><i class="fas fa-arrow-right"></i></span>
          Plataformas de <b class="primary--text">Visualización</b> interactiva de <b class="primary--text">datos</b>`,
        `<span class="fa-li primary--text"><i class="fas fa-arrow-right"></i></span>
          Chatbots para cualquier otra necesidad`
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.services {
  list-style-type: none;
  li {
    margin: 12.5px auto;
    font-size: 14pt;
  } 
}
#section-dimensions {
  min-height: 20vh;
  max-width: 1200px;
  margin: auto;
}
</style>