/*eslint-disable*/
import router from '@/router'
import axios from "axios";
import JwtService from "@/common/jwt.service";
import buildersMixin from "@/components/page/campaign/common/buildersMixin";
import * as d3 from 'd3';


let request = axios.CancelToken.source();

let pid = (given_pid) => given_pid || router.currentRoute.params.pid

let toStandard = (str) =>{
  if (!str)
    return str
  return str.normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "").toLowerCase()
}


let ApiService = axios.create({
  baseURL: process.env.VUE_APP_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

const state = {
  page_extras: undefined,
  current_extra: undefined,
};

const getters = {
  all_messages2(state){
    return state.campaigns.reduce((arr, camp)=>(
      [...arr, ...camp.messages]), [])
  },
  all_campaigns2(state){
    if (!state.campaigns)
      return []
    return state.campaigns.map(camp=>(
      {...camp, ...{name_norm: toStandard(camp.name),
        messages: camp.messages.map(msg=>
          ({...msg, ...{name_norm: toStandard(msg.name)}}))}}
    ), [])
  },
};

const mutations = {
  SET_EXTRA_PAGES(state, extras) {
    state.page_extras = extras
  },
  SET_PAGE_EXTRA(state, camp_data){
    if (camp_data){
      const messages = camp_data.messages.map(msg=>
        buildersMixin.methods.build_msg(msg))
      //console.log(messages)
      state.current_campaign = {...camp_data, ...{messages: messages}}
    }
    else
      state.current_campaign = camp_data;
  },
  SET_NEW_PAGE_EXTRA(state, msg_data){
    const camp_idx =  state.campaigns.findIndex(camp=> 
      camp.id === msg_data.campaign)
    state.campaigns[camp_idx].messages.push(msg_data)
    let new_msg = buildersMixin.methods.build_msg(msg_data, true)
    state.current_campaign.messages.push(new_msg);
  },
  ADD_RES_NEW_EXTRA(state, new_extra) {
    console.log("ADD_RES_NEW_EXTRA", new_extra)
    state.page_extras.push(new_extra);
  },
  UPDATE_RES_EXTRA(state, extra_data) {
    console.log("UPDATE_RES_EXTRA", extra_data)
    const xtra_idx = state.page_extras.findIndex(xtra=>
      xtra.id === extra_data.id)
    state.page_extras.splice(xtra_idx, 1, extra_data)
  },
};

const actions = {
  SET_HEADER({state}){
    let token = JwtService.getToken()
    if (token){
      ApiService.defaults.headers.common['Authorization'] = `Token ${token}`;
    }
    //this.$axios.setToken(token, 'Token')
  },
  FETCH_PAGE_EXTRAS({commit, dispatch}) {
    return new Promise(resolve => {
      dispatch("SET_HEADER")
      ApiService.get(`page/${pid()}/extra_config/`)
        .then(({data}) => {
          commit('SET_EXTRA_PAGES', data.results);
          return resolve(data)
        })
        .catch(err => resolve(err))
    })
  },
  GET_PAGE_EXTRA({commit, dispatch}, xtra_id){
    return new Promise (resolve => {
      dispatch("SET_HEADER")
      ApiService.get(`/page/${pid()}/extra_config/${xtra_id}/`).then(({data})=>{
        return resolve(data)
      }).catch((err)=>{
        console.log(err)
      })
    })
  },
  POST_PAGE_EXTRA({commit, dispatch}, message) {
    let id = message.id
    let post_url = id ? `${id}/` : ''
    return new Promise(resolve => {
      dispatch('SET_HEADER')
      ApiService[id ? 'put' : 'post'](
          `page/${pid()}/message/${post_url}`, message)
        .then(({data}) => {
          commit(id ? 'UPDATE_MESSAGE' : 'SET_NEW_MESSAGE', data);
          return resolve(data)
        })
        .catch(err =>{
          console.log(err)
          return resolve({error:err})
        })        
    })
  },
  PUT_PAGE_EXTRA({commit, dispatch}, [id, msg_name]) {
    return new Promise(resolve => {
      dispatch('SET_HEADER')
      ApiService.put(`page/${pid()}/message/${id}/`, {name: msg_name})
        .then(({data}) => {
          commit('UPDATE_MSG_NAME', data)
          return resolve(data)
        })
        .catch(err =>{
          console.log(err)
          return resolve({error:err})
        })        
    })
  },

};


export default {
  state,
  getters,
  actions,
  mutations,
}