<template>
  <div>
    <h2>¡Lo sentimos!</h2>
    <h3>Estamos teniendo problemas con nuestros servidores :(</h3>
    <v-btn class="accent" to="/">Ir a Home Page</v-btn>
  </div>
</template>

<script>
export default {
  name: "YeekoDown"
};
</script>

<style lang="scss" scoped>
</style>