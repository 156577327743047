<template>
  <div>
    <h2>¡Lo sentimos!</h2>
    <h3>No encontramos la página solicitada</h3>
    <v-btn class="accent mr-1" to="/">Ir a Home Page</v-btn>
    <v-btn class="accent ml-1" outlined to="/login">Iniciar Sesión</v-btn>
  </div>
</template>

<script>
export default {
  name: "NotFound"
};
</script>

<style lang="scss" scoped>
</style>