<template>
  <div>
    <h2>¡Lo sentimos!</h2>
    <h3>No encontramos la página solicitada</h3>
    <v-btn class="accent" to="dashboard/manage_pages">
    	<v-icon left>home</v-icon>
    	Regresar al inicio
    </v-btn>
  </div>
</template>

<script>
import router from "@/router";
export default {
  name: "DashboardNotFound",
};
</script>

<style lang="scss" scoped>
</style>