
var buildersMixin = {
  methods:{
    build_msg(msg, is_new=false){
      //console.log("msg:", msg)
      if (!msg.message_json)
        msg.message_json = {buttons: [], btn_jump: {}}
      if(!msg.message_json.buttons)
        msg.message_json.buttons = []
      let btn_jump = {}
      let buttons = msg.message_json.buttons
      let compl_jump = {
        selected: true,
        title: "Omitir respuesta",
        is_jump: true,
      }
      const jumb_idx = buttons.findIndex(btn=> btn.is_jump)
      if (jumb_idx >= 0)
        btn_jump = buttons.splice(jumb_idx, 1)[0]
      else
        compl_jump.selected = false
      if (
        !buttons.length 
        && msg.behavior.dashboard_extension.has_buttons
        && is_new){
        msg.message_json.buttons = [1, 2].map(b=>
          this.build_btn({
            title: `Texto botón ${b}`,
            large_title: "",
            subtitle: "",
          }))
      }
      msg.message_json.btn_jump = this.build_btn(btn_jump || {}, compl_jump)
      const add_config_df = {max_btns: 'max10', save_auto_extra: true}
      msg.addl_config = {...add_config_df, ...msg.addl_config || {}}
      msg.message_json.buttons = msg.message_json.buttons.map(btn=>
        this.build_btn(btn))
      msg.after = {
        ...{ params: {}, message: null, function: null},
        ...(msg.after || {}) }
      const compl_write = {
        //is_write: true,
        selected: !!msg.behavior.dashboard_extension.forced_write,
        title: "Respuesta Escrita",
        page_extra_config: null,
        special_behav: null,
        collection_id: null,
        cumulative: false,
      }
      msg.write_response = this.build_btn(msg.write_response || {}, compl_write)
      msg.headers = msg.header 
        ? msg.header.split("\r\n\r\n").map(head=>({text: head}))
        : []
      const btns_count = msg.message_json.buttons.length
      if (!msg.page_extra_config)
        msg.page_extra_config = msg.addl_config.save_auto_extra ? 0 : null
      return {
        ...{expanded_q: false, seqOpt: btns_count, user_config: true},
        ...msg }
    },
    rebuild_msg(msg, simple=false){
      //console.log(msg)
      const fields_msg = [
        "id", "name", "insistent", "mandatory", "is_mandatory", "order_in_campaign",
        "message", "addl_config"]
      let new_msg = {
        header: msg.headers.map(head=>head.text).join("\r\n\r\n") || null,
        behavior: msg.behavior.id,
        message_type: msg.message_type.id,
      }
      fields_msg.forEach(field=>{
        new_msg[field] = msg[field]
      })
      new_msg.page_extra_config = msg.page_extra_config || null
      const msg_json = {text: msg.message_json.text}
      let btns = msg.behavior.dashboard_extension.has_buttons
        ? (msg.message_json.buttons || [])
        : []
      if (msg.message_json.btn_jump.selected)
        btns = [...btns, msg.message_json.btn_jump]
      new_msg.write_response = msg.write_response.selected
        ? this.rebuild_btn(msg.write_response, 'write')
        : null
      msg_json.buttons = btns.map(btn=> this.rebuild_btn(btn))
      new_msg.message_json = msg_json
      const fields_after = ["message", "function", "url"]
      if (msg.after){
        //console.log(msg.after)
        let some_after = fields_after.some(field=> msg.after[field])
        if (some_after){
          new_msg.after = this.rebuild_btn(msg.after, 'after')
          //console.log(msg.after.params)
          new_msg.after.params = msg.after.params || {}
        }
        else
          new_msg.after = null
      }
      //console.log(new_msg)
      return new_msg
    },
    build_btn(btn, another={}){
      //let another_params = another.params || {}
      //delete(another.params)
      const params_df = {
        limits: [],
        circles: [],
        tags: [],
        extras: {},
        settings: null,
        title: null,
        subtitle: null,
        large_title: null,
        extra_by_msg: true,
        expanded: false,
        has_header: btn.params && !!btn.params.header || false,
        header: null,
        msg: null,
      }
      const compl_btn = {
        params: {
          ...params_df,
          //...another_params,
          ...(btn.params || {})}
      }
      if (compl_btn.params.circles.length)
        compl_btn.params.tags.push({inclussion: 'circles'})
      compl_btn.params.extras = compl_btn.params.extras || {}
      let xtras = compl_btn.params.extras
      Object.keys(xtras).forEach(key=>{ 
        compl_btn.params.tags.push({
          inclussion: 'extra',
          config_extra: key,
          value: xtras[key]
        })
      })
      const base_btn = {...{
          title: '',
          large_title: '',
          subtitle: '',
          message: null,
          function: null,
          url: null,
        }, ...another}
      /*if (another.selected && false){
        console.log(base_btn)
        console.log(btn)
        console.log(compl_btn)
      }*/
      //console.log({...base_btn, ...btn, ...compl_btn})
      return {...base_btn, ...btn, ...compl_btn}
    },
    rebuild_btn(btn, special){
      const fields_btn = [
        "message", "function", "url", "selected", "is_jump", "collection_id",
        "special_behav", "cumulative"]
      let new_btn = {}
      if (special !== 'after'){
        new_btn.title = btn.title
        new_btn.subtitle = btn.subtitle
        new_btn.large_title = btn.large_title
      }
      fields_btn.forEach(field=>{
        if (btn[field] !== undefined && btn[field] !== null)
          new_btn[field] = btn[field]
      })
      if (special == 'write')
        new_btn.page_extra_config = btn.page_extra_config
      new_btn.params = {}
      const fields_params = [
        "msg", "settings", "yeeko_id", "title", "subtitle", "large_title"]
      fields_params.forEach(field=>{
        if (btn.params[field])
          new_btn.params[field] = btn.params[field]            
      })
      if (special !== 'after'){
        new_btn.params.extra_by_msg = btn.params.extra_by_msg
        if (btn.params.has_header)
          new_btn.params.header = btn.params.header
        if (btn.params.circles.length)
          new_btn.params.circles = btn.params.circles
        new_btn.params.extras = btn.params.tags.reduce((obj, tag)=>(
          tag.config_extra !== null
            ? {...obj, ...{[tag.config_extra]: tag.value}}
            : obj
        ), {})
      }  
      return new_btn
    },

  },
}

export default buildersMixin;
