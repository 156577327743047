import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import axios from 'axios';
import VueAxios from 'vue-axios';
import router from './router'
//import ApiService from './store/base'
// import dotenv from 'dotenv'
// dotenv.config();
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import colors from 'vuetify/lib/util/colors'
import "vue-wysiwyg/dist/vueWysiwyg.css";
import store from '@/store'
// import es from './i18n/es.ts'
import wysiwyg from "vue-wysiwyg";
import VJsoneditor from 'v-jsoneditor/src/index'
// import messengerLogo from '@/assets/messenger-logo.svg'
import messengerLogo from '@/assets/MessengerLogo.vue'
import WhatsappLogo from '@/assets/WhatsappLogo.vue'

// import es from 'vuetify/es5/locale/es'
const vuetify_options = {
  /*lang: {
    locales: { es },
    current: 'es'
  },*/
  icons: {
    iconfont: 'md',
    values: {
      "messenger-logo": {
        component: messengerLogo
      },
      "whatsapp-logo": {
        component: WhatsappLogo
      },
    }
  },
  theme: {
    themes: {
      light: {
        //primary: '#ee0290', // rosa  #9ac08d verde
        info: '#116292',
        //primary: '#2fc1d8',
        primary: colors.cyan.darken2,
        //accent: '#d602ee', // #4DD0E1
        //accent: colors.purple.accent4, // #E53935
        //accent: '#FFA726', // material
        accent: '#fbb03b', // libelilab
        //secondary: colors.blue, // #fff64f dorado
        secondary: '#2fc1d8'
      },
      dark: {
        //primary: '#3f6235', //verde oscuro
        primary: '#3ce5d9', // azúl de libelilab
        accent: '6F7271', //
        secondary: '#c79400', //dorado oscuro
      },
    },
  },
};

/*Vue.use(Vuetify, {
  lang: {
    locales: {es},
    current: 'es'
  }
})*/
Vue.use(Vuetify)

Vue.use(Vuex)

Vue.use(wysiwyg, {

  hideModules: {
    "underline": true,
    "justifyLeft": true,
    "justifyCenter": true,
    "justifyRight": true,
    "headings": true,
    "code": true,
    "image": true,
    "table": true,
    "removeFormat": true,
    "separator": true,
  },

}); // config is optional. more below

Vue.use(VueAxios, axios);
Vue.use(VJsoneditor)
Vue.router = router

Vue.config.productionTip = false

//ApiService.init();

new Vue({
  router,
  store,
  vuetify: new Vuetify(vuetify_options),
  render: h => h(App),
}).$mount('#app')
