<script>
import CycleInformationCard from "@/components/landing/CycleInformationCard.vue";

export default {
  name: "CycleInformationList",
  components: {
    CycleInformationCard
  },
  data() {
    return {
      show_all_benefits: false,
      all_details: [
        {
          icon: 'fas fa-assistive-listening-systems',
          title: 'Escucha',
          details: 'Abre un canal de comunicación directo y ordenado',
          read_more_url: 'cambiar'
        },
        {
          icon: 'fas fa-comments',
          title: 'Construye',
          details: 'Las mejores ideas se construyen colectivamente',
          read_more_url: 'cambiar'
        },
        {
          icon: 'fas fa-bullhorn',
          title: 'Informa',
          details: 'Comunica los resultados y tus decisiones',
          read_more_url: 'cambiar'
        },
        {
          icon: 'fas fa-puzzle-piece',
          title: 'Segmenta a tus Miembros',
          details: 'Diferencia y controla la información y la comunicación'
        },
        {
          icon: 'fas fa-bolt',
          title: 'Incentiva la Participación',
          details: 'Aumenta el sentido de pertenencia e integración'
        },
        {
          icon: ' fas fa-lightbulb',
          title: 'Inteligencia Colectiva',
          details: 'Toma lo mejor de cada miembro. Modera sus aportes'
        }
      ]      
    };
  },
};
</script>

<template>
  <v-layout row wrap justify-center 
    class="elevation-0 landing-max-width-section text-center">
    <v-flex xs12 class="text-sm-center" my-3>
      <div class="mx-2 text-h3">
        Con <span class="secondary--text">Yeeko</span><br> mantienes un 
        ciclo de comunicación contínua
      </div>
    </v-flex>
      
    <v-row v-if="" align="stretch" justify="center">
      <v-col
        v-for="(benefit, index) in all_details"
        cols="10"
        offset="1"
        sm="4"
        offset-sm="0"
        :key="index+10"
        align="stretch"
      >
        <CycleInformationCard
          v-if="index  <= 2 || show_all_benefits"
          :card_index="index"
          :card_information="benefit"
        />
        <v-btn 
          v-if="index == 1"
          text
          color="accent"
          class="mt-6"
          @click="show_all_benefits = !show_all_benefits"
        >Beneficios
          <v-icon>
            {{show_all_benefits ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-layout>
</template>



<style lang="scss" scoped>
.card-height {
  min-height: 33vh;
}
</style>