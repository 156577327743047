<template>
  <nav>
    <v-toolbar text fixed color="primary">
      <v-toolbar-title>
        <v-img
          class="auth-title-header"
          id="logo-img"
          src="https://s3-us-west-2.amazonaws.com/cdn-yeeko/static/img/logo.png"
          alt="Logo Yeeko"
          width="95"
          contain
        />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-layout row wrap align-center>
          <v-flex xs12>
            <v-btn @click="logout" color="white" text depressed :ripple="false">
              Cerrar Sesión &nbsp;
              <v-icon size="19" :left="true">exit_to_app</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-toolbar-items>
    </v-toolbar>
  </nav>
</template>

<script>
export default {
  name: "ManagePagesHeader",
  data() {
    return {
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("LOGOUT");
      this.$store.dispatch("NOTIFY_USER", "Has cerrado sesión correctamente.");
    },
  },
  computed: {
    user: function() {
      return this.$store.state.user_details;
    },
  },
};
</script>

<style scoped lang="scss">
.v-list__tile__action {
  min-width: 30px;
}
</style>