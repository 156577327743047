
// import axios from "axios";
import JwtService from "@/common/jwt.service";
import ApiService from "./base";

// let request = axios.CancelToken.source();

const state = {
  initiatives: [],
  chambers: [],
  positions: [],
  measures: [],
  political_parties: [],
};

const getters = {
};

const mutations = {
  SET_BORDE_CATS(state, cats) {
    state.initiatives = cats.initiatives
    state.chambers = cats.chambers
    cats.positions.forEach((p)=> p.color_viz = '#3a3939')
    state.positions = cats.positions
    state.political_parties = cats.political_parties
    state.measures = cats.prizes
  },
};

const actions = {
  SET_HEADER({state}){
    let token = JwtService.getToken()
    if (token){
      ApiService.defaults.headers.common['Authorization'] = `Token ${token}`;
    }
    //this.$axios.setToken(token, 'Token')
  },
  // FETCH_YEEKOS({commit, dispatch}) {
  //   return new Promise(resolve => {
  //     dispatch("SET_HEADER")
  //   })
  // },
  GET_BORDE_CATS({commit, dispatch}, yeeko_id){
    return new Promise (resolve => {
      dispatch("SET_HEADER")
      ApiService.get(`/borde/catalogs`).then(({data})=>{
        //commit("SET_YEEKO", data)
        commit("SET_BORDE_CATS", data)
        return resolve(data)
      }).catch((err)=>{
        console.log(err)
      })
    })
  },
  POST_VOTE_FILE({state, commit, dispatch}, [new_data, appends, snack_text]){
    return new Promise (resolve => {
      const initiative_id = appends.initiative_id
      dispatch("SET_HEADER")
      console.log("new_data", new_data)
      console.log("new_data file", new_data.get("file"))
      ApiService.post(
        `/borde/initiative/${initiative_id}/vote_xls_file/`,
        new_data,
        {headers: {'Content-Type': 'multipart/form-data'}}
      )
        .then(({data})=>{
          // console.log(data)
          return resolve(data)
        })
        .catch((err)=> {
          // console.log(err)
          return resolve(err.response.data)
        })
    })
  },
  GET_INITIATIVE({state, commit, dispatch}, initiative_id){
    return new Promise (resolve => {
      dispatch("SET_HEADER")
      ApiService.get(`/borde/initiative/${initiative_id}/`)
        .then(({data})=>{
          console.log(data)
          return resolve(data)
        })
        .catch((err)=> {
          console.log(err)
          return resolve(err.response.data)
        })
    })
  },
  GET_MEASURE({state, commit, dispatch}, measure_id){
    return new Promise (resolve => {
      dispatch("SET_HEADER")
      ApiService.get(`/borde/measure?measure=${measure_id}`)
        .then(({data})=>{
          console.log(data)
          return resolve(data)
        })
        .catch((err)=> {
          console.log(err)
          return resolve(err.response.data)
        })
    })
  },
  // UPDATE_YEEKO({commit, dispatch}, [yeeko_id, params]) {
  //   return new Promise(resolve => {
  //     dispatch("SET_HEADER")
  //     ApiService.put(`/page/${pid()}/yeeko/${yeeko_id}/`, params)
  //       .then(({data}) => {
  //         commit('UPDATE_CURR_YEEKOS', ['update', data]);
  //         return resolve(data)
  //       })
  //       .catch(err => resolve(err))
  //   })
  // },
  // POST_YEEKO({commit, dispatch}, yeeko) {
  //   let id = yeeko.id
  //   let post_url = id ? `${id}/` : ''
  //   yeeko.page_id = pid()
  //   return new Promise(resolve => {
  //     dispatch('SET_HEADER')
  //     ApiService[id ? 'put' : 'post'](`/page/${pid()}/yeeko/${post_url}`, yeeko)
  //       .then(({data}) => {
  //         console.log(data)
  //         if (id)
  //           commit('UPDATE_CURR_YEEKOS', ['update', data]);
  //         else
  //           commit('ADD_YEEKO', data);
  //         return resolve(data)
  //       })
  //   })
  // },
  // DELETE_YEEKO({commit, dispatch}, yeeko_id) {
  //   return new Promise(resolve => {
  //     dispatch("SET_HEADER")
  //     ApiService.delete(`/page/${pid()}/yeeko/${yeeko_id}/`)
  //       .then(({data}) => {
  //         commit('UPDATE_CURR_YEEKOS', ['delete', yeeko_id]);
  //         return resolve(data)
  //       })
  //       .catch(err => resolve(err))
  //   })
  // },

};


export default {
  state,
  getters,
  actions,
  mutations,
}