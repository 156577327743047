<script>
export default {
  name: "HeaderUtils",
  props: {
    is_dialog: {
      type: Boolean,
      required: false,
      default: false,
    },
  },  
  computed:{
    is_small(){
      //return (this.is_dialog || this.$breakpoint.is.xsOnly)
      return (this.is_dialog || this.$vuetify.breakpoint.xsOnly)
    },
    back(){
      return require('../../../assets/brand/background2.webp')
      //url("../../assets/brand/background2.webp")
    }
  }
};
</script>

<template>
  <v-row 
    justify="center"
    align="center" 
    :style="{'height': `${is_small?12:20}0px`}"
    class="mx-0 row-image"
  >
    <v-img
      src="https://s3-us-west-2.amazonaws.com/cdn-yeeko/static/img/logo.png"
      alt="Logo Yeeko"
      height="60"
      :class="{'separate' : !is_small}"
      contain
    ></v-img>
  </v-row>
</template>

<style scoped lang="scss">

.row-image{
  background: url("../../../assets/brand/background2.webp") no-repeat center;  
  background-size: cover;
}
.separate{
  bottom: 25px;
}

</style>