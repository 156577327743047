import cookies from 'vue-cookies'
const ID_TOKEN_KEY = "id_token";
import { TOKEN_SITE } from "@/common/config";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  cookies.set(ID_TOKEN_KEY, token, "1d", null, TOKEN_SITE)
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  cookies.remove(ID_TOKEN_KEY);
};


// Para el ID de página

const PAGE_ID = 'page_id';

export const getPageId = () => {
  return window.localStorage.getItem(PAGE_ID)
};

export const savePageId = token => {
  window.localStorage.setItem(PAGE_ID, token);
}

export const destroyPageId = () => {
  window.localStorage.removeItem(PAGE_ID);
}

export default { getToken, saveToken, destroyToken, getPageId, savePageId, destroyPageId };