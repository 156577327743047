<script>

import ClientsDetails from "@/components/landing/clientsdetails.js";

export default {
  name: "Clients",
  data() {
    return {
      clients_details: ClientsDetails,
      groups: [
        {title: 'Organizaciones Aliadas', field: 'allies', grey: false},
        {title: 'Confían en Yeeko', field: 'working', grey: true},
        {title: 'Visto en', field: 'seen_at', grey: true},
      ]
    };
  },
  methods: {
    calculateOffset(index, divider, remainder) {
      return index % divider !== remainder
    }
  }
};
</script>

<template>
  <div>
    <v-row
      v-for="group in groups"
      :key="group.field"
      justify="center"
      align="center"
      class="mt-5"
    >
      <v-col cols="12" class="text-center">
        <span class="text-h4 primary--text text-decoration-underline">
          {{group.title}}
        </span>
      </v-col>
      <v-col
        v-for="(client, index) in clients_details[group.field]"
        :key="index"
        class="my-5"
        cols="6"
        sm="4"
        :md="group.grey ? 3 : 4"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <a :href="client['url']" target="_blank" v-on="on">
              <v-img
                :src="client.image_url"
                contain
                :class="{'grey-image': group.grey}"
                :alt="client.image_alt"
                :max-height="group.grey ? 130 : 100"
                class="px-3"
              ></v-img>
            </a>
          </template>
          <span>{{client.image_alt}}</span>
        </v-tooltip>
      </v-col>
    </v-row>

  </div>
</template>

<style lang="scss" scoped>
  h1 {
    text-decoration: underline;
    text-decoration-color: #de006e;
    color: black;
  }
  #section-width {
    max-width: 1200px;
    margin: auto;
  }
  .grey-image {
    filter: gray;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
  }
</style>