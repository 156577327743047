/*eslint-disable*/
import router from '@/router'
import axios from "axios";
import JwtService from "@/common/jwt.service";
import buildersMixin from "@/components/page/campaign/common/buildersMixin";
import * as d3 from 'd3';


let request = axios.CancelToken.source();

let pid = (given_pid) => given_pid || router.currentRoute.params.pid

let toStandard = (str) =>{
  if (!str)
    return str
  return str.normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "").toLowerCase()
}


let ApiService = axios.create({
  baseURL: process.env.VUE_APP_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

const state = {
  campaigns: undefined,
  current_campaign: undefined,
};

const getters = {
  messages_count(state) {
    const regexpMsg = /^mensaje_(\d{1,3})$/;
    const msgs_auto_name = state.campaigns.reduce((arr, camp)=>{
      let names_msg = camp.messages.reduce((curr_arr, msg)=>{
        const match = msg.name.match(regexpMsg);
        return match ? [...curr_arr, match[1]] : curr_arr
      }, [])
      return [...arr, ...names_msg]
    },[])
    return msgs_auto_name.length
      ? d3.max(msgs_auto_name)
      : d3.sum(state.campaigns, d => d.messages.length)
  },
  ordered_campaigns(state){
    let camps = state.campaigns
    const curr_camp = state.current_campaign
    if (!curr_camp)
      return camps
    const other_camps = camps.filter(camp=>camp.id !== curr_camp.id)
    return [curr_camp, ...other_camps]
  },
  all_messages(state){
    return state.campaigns.reduce((arr, camp)=>(
      [...arr, ...camp.messages]), [])
  },
  all_campaigns(state){
    if (!state.campaigns)
      return []
    return state.campaigns.map(camp=>(
      {...camp, ...{name_norm: toStandard(camp.name),
        messages: camp.messages.map(msg=>
          ({...msg, ...{name_norm: toStandard(msg.name)}}))}}
    ), [])
  }
};

const mutations = {
  SET_CAMPAIGNS(state, campaigns) {
    state.campaigns = campaigns
  },
  ADD_CAMPAIGN(state, campaign_data) {
    state.campaigns.push(campaign_data)
  },
  SET_CAMPAIGN(state, camp_data){
    if (camp_data){
      const messages = camp_data.messages.map(msg=>
        buildersMixin.methods.build_msg(msg) )
      state.current_campaign = {...camp_data, ...{messages: messages}}
    }
    else
      state.current_campaign = camp_data;
  },
  EDIT_CAMPAIGN(state, campaign_data){
    const camp_idx = state.campaigns.findIndex(camp=> camp.id == campaign_data.id)
    state.campaigns[camp_idx].name = campaign_data.name
    state.campaigns[camp_idx].description = campaign_data.description
    state.current_campaign.name = campaign_data.name
    state.current_campaign.description = campaign_data.description
  },
  SET_NEW_MESSAGE(state, msg_data){
    const camp_idx =  state.campaigns.findIndex(camp=> 
      camp.id == msg_data.campaign)
    state.campaigns[camp_idx].messages.push(msg_data)
    let new_msg = buildersMixin.methods.build_msg(msg_data, true)
    state.current_campaign.messages.push(new_msg);
  },
  UPDATE_MESSAGE(state, msg_data){
    const camp_idx =  state.campaigns.findIndex(camp=> 
      camp.id == msg_data.campaign)
    const msg_idx = state.campaigns[camp_idx].messages.findIndex(msg=>
      msg.id == msg_data.id)
    state.campaigns[camp_idx].messages.splice(msg_idx, 1, msg_data)
    const new_msg = buildersMixin.methods.build_msg(msg_data)
    const msg_idx2 = state.current_campaign.messages.findIndex(msg=>
      msg.id == msg_data.id)
    state.current_campaign.messages.splice(msg_idx2, 1, new_msg);
  },
  UPDATE_MSG_NAME(state, msg_data){
    const camp_idx =  state.campaigns.findIndex(camp=> 
      camp.id == msg_data.campaign)
    const msg_idx = state.campaigns[camp_idx].messages.findIndex(msg=>
      msg.id == msg_data.id)
    state.campaigns[camp_idx].messages[msg_idx].name = msg_data.name
    const msg_idx2 = state.current_campaign.messages.findIndex(msg=>
      msg.id == msg_data.id)
    state.current_campaign.messages[msg_idx2].name = msg_data.name
  },
  UPDATE_MSG_USER_EXTRAS(state, msg_data){
    //const msg_idx2 = state.current_campaign.messages.findIndex(msg=>
    //  msg.id == msg_data.id)
    //state.current_campaign.messages[msg_idx2].user_extras = msg_data.user_extras
  },
};

const actions = {
  SET_HEADER({state}){
    let token = JwtService.getToken()
    if (token){
      ApiService.defaults.headers.common['Authorization'] = `Token ${token}`;
    }
    //this.$axios.setToken(token, 'Token')
  },
  FETCH_CAMPAIGNS({commit, dispatch}) {
    return new Promise(resolve => {
      dispatch("SET_HEADER")
      ApiService.get(`page/${pid()}/campaign/`)
        .then(({data}) => {
          commit('SET_CAMPAIGNS', data);
          return resolve(data)
        })
        .catch(err => resolve(err))
    })
  },
  GET_CAMPAIGN({commit, dispatch}, camp_id) {
    return new Promise(resolve => {
      dispatch("SET_HEADER")
      ApiService.get(`page/${pid()}/campaign/${camp_id}/`)
        .then(({data}) => {
          commit('SET_CAMPAIGN', data);
          return resolve(data)
        })
        .catch(err => resolve(err))
    })
  },
  SAVE_CAMPAIGN({commit, dispatch}, camp_data) {
    const camp_id = camp_data.id
    const compl = camp_id ? `${camp_id}/` : ""
    const method = camp_id ? 'put' : 'post'
    return new Promise(resolve => {
      dispatch("SET_HEADER")
      ApiService[method](`page/${pid()}/campaign/${compl}`, camp_data)
        .then(({data}) => {
          console.log(data)
          console.log(camp_id)
          if (camp_id){
            commit('EDIT_CAMPAIGN', data);
          }
          else{
            commit('ADD_CAMPAIGN', data);
            commit('SET_CAMPAIGN', data);            
          }
          return resolve(data)
        })
        .catch(err => resolve(err))
    })
  },
  POST_MESSAGE({commit, dispatch}, message) {
    let id = message.id
    let post_url = id ? `${id}/` : ''
    return new Promise(resolve => {
      dispatch('SET_HEADER')
      ApiService[id ? 'put' : 'post'](
          `page/${pid()}/message/${post_url}`, message)
        .then(({data}) => {
          commit(id ? 'UPDATE_MESSAGE' : 'SET_NEW_MESSAGE', data);
          return resolve(data)
        })
        .catch(err =>{
          console.log(err)
          return resolve({error:err})
        })        
    })
  },
  PUT_MESSAGE_NAME({commit, dispatch}, [id, msg_name]) {
    return new Promise(resolve => {
      dispatch('SET_HEADER')
      ApiService.put(`page/${pid()}/message/${id}/`, {name: msg_name})
        .then(({data}) => {
          commit('UPDATE_MSG_NAME', data)
          return resolve(data)
        })
        .catch(err =>{
          console.log(err)
          return resolve({error:err})
        })        
    })
  },
  PUT_EXTRA_USERS({commit, dispatch}, [id, new_extra]) {
    return new Promise(resolve => {
      dispatch('SET_HEADER')
      ApiService.put(`page/${pid()}/message/${id}/change_extras/`, 
          {extra_config: new_extra})
        .then(() => {
          //commit('UPDATE_MSG_USER_EXTRAS', data)
          return resolve()
        })
        .catch(err =>{
          console.log(err)
          return resolve({error:err})
        })        
    })
  },

};


export default {
  state,
  getters,
  actions,
  mutations,
}