<template>
  <div>
    <HeaderLogin/>
    <v-content style="padding: 0px">
      <v-layout justify-center wrap>
        <v-flex class="app-width">
          <v-card class="internal-padding floating-card">
            <!--<Login v-if="$route.name == 'Login'" :login_checked="login_checked"/>-->
            <NotFound v-if="$route.name == 'NotFound'"/>
            <DashboardNotFound v-if="$route.name == 'DashboardNotFound'"/>
            <YeekoDown v-if="$route.name == 'YeekoDown'"/>
            <FBDown v-if="$route.name == 'FBDown'"/>
          </v-card>
          <FooterLogin v-if="$route.name !== 'NotFound'"/>
        </v-flex>
      </v-layout>
    </v-content>
  </div>
</template>

<script>
import HeaderLogin from "@/components/dashboard/HeaderLogin.vue";
//import Login from "@/components/dashboard/Login.vue";
import NotFound from "@/components/dashboard/NotFound.vue";
import DashboardNotFound from "@/components/dashboard/DashboardNotFound.vue";
import YeekoDown from "@/components/dashboard/YeekoDown.vue";
import FBDown from "@/components/dashboard/FacebookDownMessage.vue";
import FooterLogin from "@/components/dashboard/FooterLogin.vue";
export default {
  name: "LoginHolder",
  components: {
    HeaderLogin,
    //Login,
    NotFound,
    DashboardNotFound,
    YeekoDown,
    FBDown,
    FooterLogin
  },
  props: {
    login_checked: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.app-width {
  max-width: 550px !important;
}
</style>