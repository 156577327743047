import axios from "axios";

export default axios.create({
  baseURL: process.env.VUE_APP_URL,
  headers: {
    "Content-Type": "application/json",
    // 'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    // "Host": "https://ngrok.yeeko.org",
    // "HTTP_HOST": "https://ngrok.yeeko.org",
    // "HTTP_HOST": "https://yeeko-dashboard.ngrok.io",
    // "origin": "https://yeeko-dashboard.ngrok.io",
  }
});

