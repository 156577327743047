<template>
  <h2 v-if="error_closing">
    Tu cambios se han enviado, cierra la ventana para continuar
  </h2>
  <h2 v-else-if="petition_details.updated">
    Esta petición ya fue cambiada, intenta con otra. 
  </h2>
  <div v-else>
    <v-app-bar
      color="#ec2c22"
      dark
    >
      <v-toolbar-title>Nuevos datos de la Petición</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-img
        :class="{'mr-3':$vuetify.breakpoint.smAndUp}"
        src="https://static.emol.cl/emol50/Fotos/2016/09/22/file_20160922173745.jpg"
        aspect-ratio="1"
        max-width="120"
        max-height="40"
        class="mt-1"
      ></v-img>
    </v-app-bar>
    <v-card class="py-4" :class="{'px-4':$vuetify.breakpoint.smAndUp}">
      <v-layout column class="text-left px-3 text--primary">
        <v-row class="ml-0 mb-1" justify="start">
          <v-btn 
            color="#8FABD5"
            icon
            @click="copyURL(petition_details.ask)"
          >
            <v-icon color="red">assignment</v-icon>
          </v-btn>
          <span class="title">Título:</span>
        </v-row>
        <span>{{petition_details.ask}}</span>
        <v-divider class="my-3"></v-divider>
        <span class="title">Descripción:</span>
        <span v-html="petition_details.description"></span>
        <template v-if="petition_details.photo_update_date">
          <v-divider class="my-3"></v-divider>
          <v-row class="ml-0 mb-1" justify="start">
            <v-btn 
              color="#8FABD5"
              icon
              @click="copyURL(petition_details.photo_url)"
            >
              <v-icon color="red">assignment</v-icon>
            </v-btn>
            <span class="title">Imagen:</span>
          </v-row>
          <span>{{petition_details.photo_url}}</span>
        </template>
      </v-layout>
      <v-divider class="my-3"></v-divider>
      <v-card-actions>
        <v-col>
          <v-row justify="space-between">
            <v-btn outlined text @click="cancel">Cancelar</v-btn>
            <v-btn 
              class="accent mr-0"
              color="red"
              @click="changesSaved"
            >
              Cambios guardados
            </v-btn>
          </v-row>
        </v-col>
      </v-card-actions>
    </v-form>
    </v-card>
  </div>
</template>

<script>
import { copyToClipboard } from "@/common/helpers"  

export default {
  name: "PetitionDetails",
  props: {
    wv: {
      type: Boolean,
      required: false,
      default: true,
    }
  },  
  data() {
    return {
      petition_details: {},
      error_closing: false,
    }
  },
  methods: {
    cancel() {
      if (this.wv)
        this.$store.dispatch("CLOSE_WEBVIEW").then((success)=>{
          if (!success) this.error_closing = true
        })
    },
    copyURL(str) {
      copyToClipboard(str);
      return this.$store.dispatch(
        "NOTIFY_USER",
        "Contenido copiado al portapapeles!"
      );
    },
    changesSaved(){
      this.$store
        .dispatch("PETITION_CHANGED")
        .then(() => {
          this.$store.dispatch("NOTIFY_USER", "Gracias, notificaremos al usuario de inmediato");
          if (this.wv)
            this.$store.dispatch("CLOSE_WEBVIEW").then((success)=>{
              if (!success) this.error_closing = true
            })
        })
        .catch(error =>
          this.$store.dispatch(
            "NOTIFY_USER",
            `Tuvimos un problema, repórtalo a Yeeko. Error: ${error}`
          )
        );
    },
  },
  created(){
    this.$store.dispatch("GET_PETITION")
      .then((response)=>{
        this.petition_details = response.data
      })
  }
};
</script>

<style lang="scss" >
</style>