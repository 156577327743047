<template>
  <h2 v-if="error_closing"> Tu cambios se han enviado, cierra la ventana para seguir conversando</h2>
  <div v-else>
    <v-app-bar
      color="#ec2c22"
      dark
    >
      <v-toolbar-title>Edita tu petición</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-img
        :class="{'mr-3':$vuetify.breakpoint.smAndUp}"
        src="https://static.emol.cl/emol50/Fotos/2016/09/22/file_20160922173745.jpg"
        aspect-ratio="1"
        max-width="120"
        max-height="40"
        class="mt-1"
      ></v-img>
    </v-app-bar>
    <v-card class="py-4" :class="{'px-4':$vuetify.breakpoint.smAndUp}">
      <v-form v-model="petition_form" ref="petition_form" >
        <v-layout column class="text-left px-3 text--primary">
          <span 
            class="title mr-4 mb-1"
            id="title"
            style="vertical-align: middle;"
          >
            Título:
          </span>
          <!--<v-btn 
            color="blue" 
            text
            class="text-sm-right"
            @click="show_help = !show_help"
          >Ayuda
            <v-icon color="primary">
              {{ show_help ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}
            </v-icon>
          </v-btn>
          <v-slide-y-transition>
            <v-card v-if="show_help" class="blue lighten-4 text-left mb-3">
              <v-card-text class="text-sm-left">
                <p class="mb-0">
                  <b> Explícalo brevemente y yendo al punto</b>
                </p>
                <p class="mb-0">
                  Ejemplo: "Compren huevos orgánicos y libres de hormonas para su restaurante"
                </p>
                <p>
                  No: "Alto al tratamiento inhumano de gallinas en granjas donde se les obliga a comer..."
                </p>
                <p class="mb-0">
                  <b> Propón una solución</b>
                </p>
                <p class="mb-0">
                  Ejemplo: "Elevar el salario de los trabajadores de la fábrica de 40 a 50 pesos la hora"
                </p>
                <p>
                  No: "Alto a la desigualdad de salarios en el país"
                </p>
              </v-card-text>
            </v-card>
          </v-slide-y-transition>-->
          <v-textarea
            :counter="90"
            class="mt-1"
            v-model="petition_details.ask"
            color="accent"
            :rules="ask_rules"
            auto-grow
            rows="3"
            outlined
            requiered
            label="¿Qué quieres lograr?"
            hint="No olvides plantear aquí el problema que quieres resolver"
            placeholder=""
          ></v-textarea>

          <span 
            id="description"
            class="title mr-4 mb-1"
            style="vertical-align: middle;"
          >
            Descripción (tu historia):
          </span>
          <wysiwyg class="text-sm-left" v-model="petition_details.description"/>

          <v-col align="center" class="pb-1" id="description">
            <span class="title mr-4 mb-1" style="vertical-align: middle;">Imagen:</span>
            <v-row justify="center">
              <v-img
                :src="petition_details.photo_url || default_image"
                class="ma-2 image-change"
                contain
              >
                <v-overlay
                  absolute
                  :dark="false"
                  color="#878787"
                  :value="overlay"
                >
                  <v-btn
                    style="bottom: -68px;"
                    color="success"
                    @click="indirectAdd()"
                  >
                    Cambiar imagen
                  </v-btn>
                </v-overlay>
              </v-img>
            </v-row>
            <v-row justify="center" v-if="!overlay">
              <v-btn
                color="primary"
                class="text-center"
                text
                @click="indirectAdd()"
                :block="false"
                max-width="170"
              >
                Cambiar
              </v-btn>
            </v-row>
          </v-col>
          <input 
            type="file"
            accept="image/x-png,image/gif,image/jpeg"
            id="photo_file"
            style="display:none"
            multiple
            @change="addPhoto($event)"
          >
        </v-layout>
        <v-divider class="mb-2"></v-divider>
        <v-card-actions>
          <v-col>
            <v-row justify="space-between">
              <v-btn outlined text @click="cancel">Cancelar</v-btn>
              <v-btn 
                class="accent mr-0"
                color="red"
                @click="savePetition"
                :disabled="!petition_form"
              >
                Guardar Cambios
              </v-btn>
            </v-row>
          </v-col>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>

export default {
  name: "EditPetition",
  props: {
    wv: {
      type: Boolean,
      required: false,
      default: true,
    }
  },  
  data() {
    return {
      overlay: true,
      petition_form: undefined,
      formData: new FormData(),
      default_image: "https://static.change.org/images/image-placeholder-drop.svg",
      //show_help: false,
      petition_details: {},
      ask_rules: [
          v => !!v || "Tu petición no puede estar vacía",
          v => (!!v && v.length < 90) ||
            "Tu petición no puede rebasar los 90 caracteres"
      ],
      error_closing: false,
    }
  },
  methods: {
    cancel() {
      if (this.wv)
        this.$store.dispatch("CLOSE_WEBVIEW").then((success)=>{
          if (!success) this.error_closing = true
        })
    },
    indirectAdd(){
      document.getElementById('photo_file').click();
    },
    addPhoto(e){
      let files = e.target.files || e.dataTransfer.files;
      this.formData.append("photo", files[0], files[0].name);
      this.petition_details.photo_url =  URL.createObjectURL(files[0]);
      this.overlay = false;
    },
    savePetition(){
      if (this.$refs.petition_form.validate()){
        this.formData.append("ask", this.petition_details.ask)
        this.formData.append("description", this.petition_details.description)
        this.$store
          .dispatch("UPDATE_PETITION", this.formData)
          .then(() => {
            this.$store.dispatch("NOTIFY_USER", "Tus cambios se verán pronto reflejados");
            this.$refs.petition_form.reset();
            if (this.wv)
              this.$store.dispatch("CLOSE_WEBVIEW").then((success)=>{
                if (!success) this.error_closing = true
              })
          })
          .catch((error) =>{
            console.log(error)
            this.$store.dispatch(
              "NOTIFY_USER",
              `Tuvimos un problema al actualizar tu petición. Error: ${error} ... ${error.errors}`
            )
          });
      }
    },
  },
  created(){
    this.$store.dispatch("GET_PETITION")
      .then((response)=>{
        this.petition_details = response.data
      })
  },
};
</script>

<style lang="scss" >
.image-change{
  max-width: 400px !important;
  width: 70%;
}
</style>