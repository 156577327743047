<template>
  <div>
    <ManagePagesHeader/>
    <v-content >
      <v-layout justify-center wrap mt-3 >
        <v-flex xs12 sm8 md7 lg6 xl4 class="manage-pages-content">
          <router-view></router-view>
        </v-flex>
      </v-layout>
    </v-content>
  </div>
</template>

<script>
import ManagePagesHeader from "@/components/manage_pages/ManagePagesHeader.vue";
import router from "@/router.js";

export default {
  name: "ManagePagesHolder",
  components: {
    ManagePagesHeader,
  },
  data() {
    return {
      first_steps_data: [],
      bot_name: "",
      user_data: undefined,
      fb_sign_in_params: {
        scope: `pages_read_engagement, pages_messaging, pages_show_list`,
        return_scopes: true,
        authType: "rerequest",
        enableProfileSelector: true
      }
    };
  },
  mounted() {
    console.log("ManagePageHolder")
  },
}
</script>

<style lang="scss" scoped>
.manage-pages-content{
  max-width: 800px;
}
</style>