const ClientsDetails = {
  allies: [
    {
      image_url: require("@/assets/brand/clients/change.png"),
      image_alt: 'Change.org',
      url: 'https://change.org'
    },
    {
      image_url: require("@/assets/brand/clients/nosotrxs2.png"),
      image_alt: 'Nosotrxs',
      url: 'https://nosotrxs.org'
    },
    {
      image_url: require("@/assets/brand/clients/altec.png"),
      image_alt: 'ALTEC',
      url: 'https://altec.lat/'
    },
    {
      image_url: require("@/assets/brand/clients/borde.png"),
      image_alt: 'Borde',
      url: 'https://borde.mx/'
    },
    {
      image_url: require("@/assets/brand/clients/socialtic.svg"),
      image_alt: 'Social TIC',
      url: 'https://socialtic.org/'
    },
    {
      image_url: require("@/assets/brand/clients/update.png"),
      image_alt: 'Instituto Update',
      url: 'https://www.institutoupdate.org.br/es/principal/'
    },    
  ],
  working: [
    {
      image_url: require("@/assets/brand/clients/ifad.webp"),
      image_alt: 'Ifad'
    },
    {
      image_url: require("@/assets/brand/clients/onum.webp"),
      image_alt: 'ONU Mujeres'
    },
    {
      image_url: require("@/assets/brand/clients/unam.webp"),
      image_alt: 'UNAM'
    },
    {
      image_url: require("@/assets/brand/clients/pnud.png"),
      image_alt: 'PNUD'
    },
    {
      image_url: require("@/assets/brand/clients/kybernyus.webp"),
      image_alt: 'Kybernus'
    },
    {
      image_url: require("@/assets/brand/clients/ollin.png"),
      image_alt: 'Ollin'
    },
    {
      image_url: require("@/assets/brand/clients/cieg.webp"),
      image_alt: 'CIEG'
    },
    {
      image_url: require("@/assets/brand/clients/igualdadgenero.png"),
      image_alt: 'Coordinación para la Igualdad de Género'
    },
    {
      image_url: require("@/assets/brand/clients/circov.webp"),
      image_alt: 'Circo Volador'
    },
    {
      image_url: require("@/assets/brand/clients/extituto.webp"),
      image_alt: 'Extituto'
    },
    {
      image_url: require("@/assets/brand/clients/movilizatorio.webp"),
      image_alt: 'Movilizatorio'
    },    
    {
      image_url: require("@/assets/brand/clients/comanticorrupcion.webp"),
      image_alt: 'Comunidad Anticorrupción'
    },
    {
      image_url: require("@/assets/brand/clients/ahora.webp"),
      image_alt: 'Ahora'
    },
    {
      image_url: require("@/assets/brand/clients/escueladeactivismo.webp"),
      image_alt: 'Escuela de Activismo Ciudadano'
    },    

    /*{
      image_url: require("@/assets/brand/clients/gaston.webp"),
      image_alt: 'Gastón Independiente'
    },
    {
      image_url: require("@/assets/brand/clients/jovenes.webp"),
      image_alt: 'Los Jóvenes Proponen'
    },
    {
      image_url: require("@/assets/brand/clients/odesyr.webp"),
      image_alt: 'Odesyr'
    },
    {
      image_url: require("@/assets/brand/clients/pormexico.webp"),
      image_alt: 'Por México Hoy'
    },
    {
      image_url: require("@/assets/brand/clients/redim.webp"),
      image_alt: 'REDIM'
    },
    {
      image_url: require("@/assets/brand/clients/telar.webp"),
      image_alt: 'Telar Social México'
    },
    {
      image_url: require("@/assets/brand/clients/arkemetria.webp"),
      image_alt: 'Arkemetría Social'
    },*/
  ],
  seen_at: [
    {
      image_url: require("@/assets/brand/clients/adn.webp"),
      url: 'https://twitter.com/ADNOpinion/status/1000068992957014016/video/1',
      image_alt: 'ADN 40'
    },
    {
      image_url: require("@/assets/brand/clients/altec.webp"),
      url: 'https://altec.lat/es/cuando-el-chat-y-los-bots-se-conocen-para-abrir-los-procesos-legislativos-en-mexico-yeeko/',
      image_alt: 'ALTEC'
    },
    {
      image_url: require("@/assets/brand/clients/aristegui.webp"),
      url: 'https://aristeguinoticias.com/2609/mexico/ahora-abre-consulta-sobre-candidatura-presidencial-independiente-de-alvarez-icaza/',
      image_alt: 'Aristegui Noticias'
    },
    /* No se usa el de NewsWeek porque no se ha logrado ajustar su posición*/
    {
      image_url: require("@/assets/brand/clients/newsweek.webp"),
      url: 'https://newsweekespanol.com/2019/05/yeeko-bots-acuerdos/',
      image_alt: 'Newsweek México'
    },
    // {
    //   image_url: require("@/assets/brand/clients/red.webp"),
    //   url: 'https://www.redinnovacion.org/articulo/yeeko-la-startup-mexicana-para-la-participaci%C3%B3n-ciudadana',
    //   image_alt: 'Red de Innovación'
    // }
  ]
}

export default ClientsDetails;