<template>
  <v-card class="" flat>
    <h1>HOLA YEEKO. BIENVENIDO OTRA VEZ</h1>
  </v-card>
</template>

<script>



export default {
  name: 'DetailYeekonsulta',
}
</script>
<style lang="scss" scoped>

</style>