<template>
  <v-app-bar app elevation="2" id="app-landing">
    <img :src="logo_img" alt="Logo Yeeko" style="max-height: 42px;" >
    <v-spacer></v-spacer>
    <v-toolbar-items >
      <!-- class="hidden-sm-and-down" -->
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn text class="primary--text" href="https://web.yeeko.org/quienes_somos">
          Quiénes Somos
        </v-btn>
        <v-btn text class="primary--text" href="https://m.me/yeekoapp?ref=:1255">
          Cómo Funciona
        </v-btn>
        <v-btn class="primary--text" text to="/dashboard/login">
          Iniciar Sesión
        </v-btn>

      </template>
      <v-menu offset-y v-else>
        <template v-slot:activator="{ on }">
          <v-btn
            class="primary--text"
            text
            v-on="on"
            icon
          >
            <v-icon>menu</v-icon>
            
          </v-btn>
        </template>
        <v-list>
          <v-list-item href="https://web.yeeko.org/quienes_somos">
            <v-list-item-title class="primary--text">QUIÉNES SOMOS</v-list-item-title>
          </v-list-item>
          <v-list-item >
            <v-list-item-title class="primary--text">CÓMO FUNCIONA</v-list-item-title>
          </v-list-item>
          <v-list-item to="/dashboard/login">
            <v-list-item-title class="primary--text">INICIAR SESIÓN</v-list-item-title>
          </v-list-item>
          
        </v-list>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
export default {
  name: "LandingNav",
  data() {
    return {
      logo_img: require("@/assets/brand/yeeko.webp")
    }
  }
};
</script>

<style lang="scss" scoped>
</style>