import * as d3 from 'd3';

let Parties = function(legend_group, votes, width_party, measure) {

  const top = measure ? 90 : 20

  // Add image of the party
  //   legend_group
  //     .selectAll('image')
  //     .data(votes)
  //     .enter()
  //     .append('image')
  //     .attr('xlink:href', d => d.logo.includes('http')
  //         ? d.logo
  //         : `http://localhost:8000${d.logo}`)
  //     .attr('x', (d, i) => i * width_party + 4)
  //     .attr('y', 20)
  //     .attr('width', width_party - 8)
  //     .attr('height', width_party - 8)

  legend_group
    .selectAll('circle.logo')
    .data(votes)
    .enter()
    .append('circle')
    .classed('logo', true)
    .attr('cx', (d, i) => i * width_party + width_party / 2)
    .attr('cy', width_party / 2 + top)
    .attr('r', width_party / 2 - 8)
    .attr('fill', d => d.color_viz)

  legend_group
    .selectAll('text.logo')
    .data(votes)
    .enter()
    .append('text')
    .text(d => d.short_name)
    .classed('logo', true)
    .attr('x', (d, i) => i * width_party + width_party / 2)
    .attr('y', width_party / 2 + (top + 4))
    .attr('font-size', 30)
    .attr('font-weight', 'bold')
    .attr('font-family', 'Montserrat')
    .attr('fill', 'rgb(255,255,255)')
    .attr('text-anchor', 'middle')
    .attr('alignment-baseline', 'middle')
    .attr('dominant-baseline', 'middle')
}

export default Parties
