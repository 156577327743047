<script>

import HeaderUtils from '../utils/Header.vue';
import FooterUtils from "../utils/Footer.vue";
import LoginHolder2 from "./LoginHolder2.vue";

export default {
  name: "UtilsHolder",
  components: {
    HeaderUtils,
    FooterUtils,
    LoginHolder2
  },
  data() {
    return {
      show_footer: false,
    }
  },
  mounted(){
    this.show_footer = true
  }
}
</script>

<style lang="scss">
.up-report{
  margin-top : -40px !important;
}
.utils-width {
  max-width: 550px !important;
}
.floating-card {
  margin-top: -60px;
}
</style>

<template>
  <v-main>
    <v-container pa-0 fluid>
      <v-flex id="app-width" fluid style="width: 100%">
        <HeaderUtils/>
        <v-layout justify-center wrap>
          <v-flex class="utils-width">
            <v-card
              class="pa-1 pa-sm-5"
              _class="{'floating-card': $breakpoint.is.smAndUp}"
              :class="{'floating-card': $vuetify.breakpoint.smAndUp}"
              
            >
              <LoginHolder2/>
            </v-card>
            <FooterUtils v-if="show_footer"/>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-container>
  </v-main>
</template>
