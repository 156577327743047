<script>
//import Policy from '@/components/misc/Policy.vue'
import { IS_CUSTOM } from "@/common/config";
export default {
  name: "FooterUtils",
  data() {
    return {
      show_policy_and_terms: false,
      is_custom: IS_CUSTOM,
    };
  },
};
</script>

<template>
  <v-row justify="space-between" align="center" class="mx-0 mt-1">
    <h4 class="grey--text" v-if="is_custom">
      Fiscalía General de Justicia de la Ciudad de México.
    </h4>
    <h4 class="grey--text" v-else>
      Yeeko Inteligencia Colectiva SAPI de CV.  {{ new Date().getFullYear() }}
    </h4>
    <h5 class="grey--text">
      <v-btn
        v-if="is_custom"
        text
        href="https://fgjcdmx.gob.mx/terminos-y-condiciones"
        target="_blank"
        color="grey darken-1"
      >Aviso de Privacidad</v-btn>
      <v-btn
        v-else
        text
        to="/policies"
        color="grey darken-1"
      >Aviso de Privacidad</v-btn>
    </h5>
  </v-row>
</template>