/*eslint-disable*/
import router from '@/router'
import axios from "axios";
import JwtService from "@/common/jwt.service";
import fileDownload from 'js-file-download';

let pid = (given_pid) => given_pid || router.currentRoute.params.pid



let DownloadService = axios.create({
  baseURL: process.env.VUE_APP_URL,
  headers: {
    responseType: 'blob',
    //"Allow-Control-Allow-Origin": "*",
    //"Content-Type" : "application/octet-stream"
    //"Content-Type" : "application/vnd.ms-excel"
    //responseType: 'arraybuffer'
  }
});

const state = {
  
  
  
};

const mutations = {
};

const actions = {
  SET_HEADER({state}, is_download=false){
    let token = JwtService.getToken()
    //let token = '51c79c5e503c92ab5466d72c38938f1a0833c09a'

    if (token){
      DownloadService.defaults.headers.common['Authorization'] = `Token ${token}`;
    }
    //this.$axios.setToken(token, 'Token')
  },
  DOWNLOAD_EXCEL_TEST({commit, dispatch}, export_name) {
    return new Promise(resolve => {
      dispatch('SET_HEADER', true)
      //DownloadService.get('/record/record_history/export/30/')
      DownloadService.get(`/page/${pid()}/${export_name}/`)
        .then((res) => {
          console.log(res)
          //fileDownload(res.data, 'Registros - Informe mensual..xlsx');
          fileDownload(res.data, 'Usuarios.xlsx');
          //return resolve(data)
        })
    })
  },
};


export default {
  state,
  actions,
  mutations,
}