<script>
export default {
  name: "ChatbotExplanation",
  data() {
    return {
      chatbot_image: require("@/assets/brand/focus.png"),
      other_image: require("@/assets/interaction.svg"),
    };
  }
};
</script>

<template>
  <v-card color="#66c5b4" v-if="true" tile elevation="0" class="mt-2">
    <v-row no-gutters align="center">
      <v-col cols="4" sm="5">
        <img :src="other_image" class="mx-n10">
      </v-col>
      <v-col cols="8" sm="7">
        <v-row justify="center" align="center">
          <v-col cols="12" align="center" class="text-h5">
            <span class="font-weight-bold">
              Un moderador virtual (chatbot)
              <br>
            </span>
            <div class="mt-2 mb-4">
              guía todas las conversaciones
            </div>
          </v-col>
          <v-col cols="12" align="center">
            <v-btn 
              href="https://m.me/yeekoapp?ref=:1255"
              color="accent" 
              large dark 
              elevation="4">
              Cómo funciona
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>