/*eslint-disable*/
import router from '@/router'
import axios from "axios";
import JwtService from "@/common/jwt.service";
import ApiService from "./base";

let request = axios.CancelToken.source();

const state = {
  circle_types: undefined,
};

const getters = {
};

const mutations = {
};

const actions = {
  SET_HEADER({state}){
    let token = JwtService.getToken()
    if (token){
      ApiService.defaults.headers.common['Authorization'] = `Token ${token}`;
    }
    //this.$axios.setToken(token, 'Token')
  },
};


export default {
  state,
  getters,
  actions,
  mutations,
}