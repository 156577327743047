<template>
  <div>
    <h4 class="grey--text">Yeeko Inteligencia Colectiva SAPI de CV. 2022</h4>
    <h5 class="grey--text">
      <a @click="show_policy_and_terms = true" id="privacy">
        AVISO DE PRIVACIDAD
      </a>
    </h5>
    <v-dialog v-model="show_policy_and_terms" max-width="1300">
			<Policy/>

		</v-dialog>
  </div>
</template>

<script>
import Policy from '@/components/misc/Policy.vue'
export default {
  name: "FooterLogin",
  components: {
		Policy
	},
	data() {
		return {
			show_policy_and_terms: false
		}
	}
};
</script>

<style lang="scss" scoped>
#privacy {
  color: #9e9e9e !important;
  caret-color: #9e9e9e !important;
}
</style>