<template>
  <div>
    <div id="loading-padding" v-if="is_logging_in && $route.name !== 'Login'">
      <v-progress-circular :size="50" color="primary" indeterminate>
      </v-progress-circular>
    </div>
    <div v-else>
      <router-view :login_checked="!is_logging_in"></router-view>
    </div>
  </div>
</template>

<script>
import router from "@/router.js";
import { mapState } from "vuex";

export default {
  name: "DashboardHolder",
  computed: {
    ...mapState({
      is_logging_in: state => state.auth.is_logging_in,
    }),
  },
  created(){
    //console.log("DashboardHolder")
    this.$store.dispatch("CHECK_AUTH_SIMPLE")
    console.log("DashboardHolder")
    // this.$store.dispatch("LOAD_ONLY_FB_SDK")
    // setTimeout(() => {
    //   // this.$store.dispatch("LOAD_FB_SDK")
    //   this.$store.dispatch("CHECK_AUTH_SIMPLE")
    // }, 5000)
  },
};
</script>

<style lang="scss" scoped>
.app-width {
  max-width: 800px;
}
#loading-padding {
  padding: 25px;
}
</style>