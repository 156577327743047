import Vue from 'vue'
import Router from 'vue-router'
import DashboardHolder from '@/components/dashboard/DashboardHolder.vue'
import LoginHolder from '@/components/dashboard/LoginHolder.vue'
//import LoginHolder2 from '@/components/dashboard/login/LoginHolder2.vue'
import ManagePagesHolder from '@/components/manage_pages/ManagePagesHolder.vue'
import CreateDemo from '@/components/manage_pages/CreateDemo.vue'
import ManagePages from '@/components/manage_pages/ManagePages.vue'
import LandingHolder from '@/components/landing/LandingHolder.vue'
import UtilsHolder from '@/components/dashboard/login/UtilsHolder.vue'
import WebviewHolder from '@/components/webviews/WebviewHolder.vue'
import EditPetition from '@/components/webviews/EditPetition.vue'
import PetitionDetails from '@/components/webviews/PetitionDetails.vue'
import DetailYeekonsulta from '@/components/yeeko_web/DetailYeekonsulta.vue'
import BordeScore from '@/components/BordeScore.vue'
import InitiativeViz from '@/components/InitiativeViz.vue'
import { IS_CUSTOM } from "@/common/config";

Vue.use(Router)

const first_landing = IS_CUSTOM
  ? {
    path: '',
    redirect: { name: 'Dashboard' },
    meta: { title: 'Dashboard' },
  }
  : {
    path: '',
    name: 'Landing',
    component: LandingHolder,
    meta: { title: 'Construye Consensos con Yeeko' },
  }

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    first_landing,
    {
      path: '/yeeko_down',
      name: 'YeekoDown',
      component: () => import('@/components/dashboard/LoginHolder.vue'),
    },
    {
      path: '/login',
      redirect: { name: 'Login' },
      meta: { title: 'Inicia sesión en yeeko.org' },
    },
    {
      path: '/after_facebook',
      meta: { title: 'Espera un momento en lo que redireccionamos' },
      component: () => import('@/components/dashboard/login/AfterFacebook.vue'),
    },
    {
      path: '/manage_pages',
      redirect: { name: 'ManagePages' },
      meta: { title: 'Tus páginas - Yeeko' },      
    },
    {
      path: '/dashboard',
      component: DashboardHolder,
      name: "Dashboard",
      meta: { title: 'Dashboard' },
      children:[
        {
          path: 'login',
          name: 'Login',
          //component: LoginHolder,
          component: UtilsHolder,
          meta: { title: 'Inicia Sesión' },
        },
        {
          path: '/reset',
          name: 'Reset',
          component: () => import('./components/dashboard/login/Reset.vue')
        },        
        {
          path: 'manage_pages',
          component: ManagePagesHolder,
          children:[
            {
              path: '',
              name: 'ManagePages',
              component: ManagePages,
            },
            {
              path: 'create_demo',
              name: 'CreateDemo',
              component: CreateDemo,
            },
          ],
        },
        {
          path: ':pid',
          component: () => import('@/components/page/PageHolder.vue'),
          meta: { title: 'Dashboard' },
          children:[
            {
              path: '',
              redirect: { name: 'PageHome' },
            },
            {
              path: 'home/',
              name: 'PageHome',
              component: () => import('@/components/page/home/PageHome.vue'),
            },
            {
              path: 'page_configuration/',
              name: 'PageConfig',
              component: () => import('@/components/page/page_configuration/PageConfiguration.vue'),
            },
            {
              path: 'first_interaction/',
              name: 'FirstInteractionChart',
              component: () => import('@/components/page/first_interaction/FirstInteractionChart.vue'),
            },
            {
              path: 'change_users/',
              name: 'ChangeUsers',
              component: () => import('@/components/page/first_interaction/ChangeUsers.vue'),
            },
            {
              path: 'borde/',
              name: 'BordeHome',
              component: () => import('@/components/page/borde/BordeHome.vue'),
            },
            {
              path: 'tracking/',
              name: 'Tracking',
              component: () => import('@/components/page/tracking/TrackingHolder.vue'),
              meta: { title: 'Seguimiento de mensajes' },
              children:[
                {
                  path: ':tracking_id',
                  name: 'TrackingHeader',
                  meta: { title: 'Detalle de Conversación' },
                }
              ],
            },
            {
              path: 'bot_introduction/',
              name: 'BotIntroduction',
              component: () => import('@/components/page/bot_introduction/BotIntroduction.vue'),
            },
            {
              path: 'campaigns/',
              name: 'Campaigns',
              component: () => import('@/components/page/campaign/CampaignList.vue'),
              meta: { title: 'Campañas' },
              children:[
                {
                  path: ':campaign_name',
                  name: 'CampaignDetail',
                  meta: { title: 'Detalle de Campaña' },
                }
              ],
            },
            {
              path: 'extras/',
              name: 'Extras',
              component: () => import('@/components/page/extras/ExtraList.vue'),
              meta: { title: 'Variables de participantes' },
            },
            {
              path: 'users/',
              redirect: { name: 'participants' },
              meta: { title: 'Lista de usuarios' },
            },
            {
              path: 'users_list/',
              name: 'users',
              component: () => import('@/components/page/users/table/UsersListHolder.vue'),
              children:[{
                  path: ':users_type',
                  name: 'UsersList',
                  meta: { title: 'Lista de usuarios' },
                }
                /*
                  {
                    path: 'participants',
                    name: 'Participants',
                    meta: { title: 'Participantes' },
                  },
                  {
                    path: 'invitations',
                    name: 'Invitations',
                    meta: { title: 'Invitaciones' },
                  },
                  {
                    path: 'staff',
                    name: 'Staff',
                    meta: { title: 'Roles Administrativos' },
                  },*/
              ]
            },
            {
              path: 'yeekonsultas/',
              name: 'Yeekonsultas',
              component: () => import('@/components/page/yeeko/YeekoList.vue'),
            },
          ]
        },
      ]
    },
    {
      path: '/change_wv',
      component: WebviewHolder,
      name: "WebviewChange",
      children:[
        /*
        {
          scrollBehavior: function(to, from, savedPosition) {
            if (to.hash) {
              return {selector: to.hash}
            } else {
              return {x: 0, y: 0}
            }
          },*/
        {
          path: 'petition_edit/:petition_id',
          name: 'EditPetition',
          component: EditPetition,
          meta: { title: 'Change.org' },
        },
        {
          path: 'petition_diff/:petition_id',
          name: 'PetitionDetails',
          component: PetitionDetails,
          meta: { title: 'Detalles de la propuesta' },
        },
      ]
    },
    {
      path: '/borde_score',
      component: BordeScore,
    },
    {
      path: '/initiative/:initiative_id',
      name: 'InitiativeViz',
      meta: { title: 'Visualización de Iniciativa' },
      component: InitiativeViz,
    },
    {
      path: '/:yeeko_id/:slug',
      name: 'Yeekonsulta',
      component: DetailYeekonsulta,
    },
    {
      path: '/home',
      component: LandingHolder,
    },
    {
      path: '*',
      name: 'NotFound',
      component: () => import('@/components/dashboard/LoginHolder.vue'), // 404
    },
    {
      path: 'FBDown',
      name: 'FBDown',
      component: () => import('@/components/dashboard/LoginHolder.vue'), // FB No funciona
    },
    {
      path: '/proposals',
      name: 'ProposalsTest',
      component: () => import('@/components/page/yeeko/CreateProposal.vue'),
    },
    {
      path: '/policies',
      name: 'Policies',
      component: () => import('@/components/misc/Policy.vue'),
    },
  ]
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router
