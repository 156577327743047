<template>
  <div>
    <h2>¡Vaya!</h2>
    <h3>Parece ser que el servidor de Facebook no funciona correctamente,</h3>
    <h3>por favor intenta acceder más tarde.</h3>
    <img src="../../assets/facebookdown.jpeg" alt="Facebook Down" height="200">
  </div>
</template>

<script>
export default {
  name: "FBDown"
};
</script>
