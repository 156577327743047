<template>
  <div id="header" class="auth-header">
    <div class="auth-title-header">
      <img
        src="https://s3-us-west-2.amazonaws.com/cdn-yeeko/static/img/logo.png"
        alt="Logo Yeeko"
        width="150"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderLogin"
};
</script>

<style scoped lang="scss">

.auth-header {
  color: #fff;
  text-align: center;
  padding: 1%;
  height: 200px;
  background-size: cover;
  background: url("../../assets/brand/background2.webp") no-repeat center;
  .auth-title-header {
    width: auto;
    height: 100%;
    margin: auto;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    font-size: 3em;
    padding: 2%;
    color: #fff;
    /* margin-top: 30px; */
  }
}

@media screen and (min-width: 1264px) {
  .auth-header .auth-title-header {
    margin-top: 20px;
  }
}
@media screen and (max-width: 1263px) {
  .auth-header .auth-title-header {
    margin-top: 30px;
  }
}
</style>