<script>
import authMixin from "./authMixin";

export default {
  name: 'LoginMail',
  mixins: [authMixin],  
  components: {
    
  },
  data(){
    return {
      alert: false,
      login_data: undefined,
      email: undefined,
      password: undefined,
    }
  },
  methods:{
    login() {
      if(this.$refs.login_ref.validate()){
        let content = {email: this.email, password : this.password}
        this.$store.dispatch('LOGIN_MAIL', content)
          .then(res=>{
            if (res.error){
              //this.$emit('set-alert', JSON.stringify(res.error.response.data))
              this.alert = true;
              this.password= "";
            }
            else
              this.$store.dispatch('YEEKO_LOGIN_MAIL')
          })
      }
    },
  },
}
</script>

<template>
  <v-form v-model="login_data" ref="login_ref" lazy-validation>
    <v-card-text class="pb-0">
      <v-alert 
        type="error"
        :value="alert"
        border="bottom"
        transition="scale-transition"
      >
        Usuario o contraseña incorrectas
      </v-alert>
      <v-text-field
        label="Correo electrónico"
        name="email"
        outlined
        prepend-icon="mail"
        type="email"
        v-model="email"
        :rules="[rules.email, rules.user_name]"
      ></v-text-field>
      <v-text-field
        id="password"
        label="Contraseña"
        @keyup.enter="login"
        outlined
        name="current-password"
        prepend-icon="lock"
        type="password"
        v-model="password"
        :rules="[rules.password]"
      ></v-text-field>
    </v-card-text>
    <v-card-actions class="pt-0">
      <v-spacer></v-spacer>
      <v-btn 
        color="accent" 
        @click="login()"
        :disabled="!login_data"
        class="px-8"
        v-text="'Iniciar Sesión'"
      ></v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-form>
</template>