/*eslint-disable*/
import router from '@/router'
import * as d3Array from 'd3-array';
import * as d3 from 'd3';
import ApiService from "./base";
import JwtService from "@/common/jwt.service";

let pid = (given_pid=null) => given_pid || router.currentRoute.params.pid
const petition_id = router.currentRoute.params.petition_id

const state = {
  //user  
  snack: '',
  //page
  is_loading_page: true,
  current_page_details: {},
  //initial_collect
  has_initial_collect: false,
  initial_collect_questions: [],
  //circles
};

const getters = {
  snack: state => state.snack,
  currentPage(state) {
    return state.current_page_details;
  },
  customized(state) {
    return state.current_page_details.first_steps_finished;
  },
  users_count(state) {
    return state.current_page_details.users_count.users;
  },
  inbox_count(state) {
    return state.current_page_details.inbox_count;
  },
  is_page_test(state) {
    return state.current_page_details.test || false;
  },  
  is_demo(state) {
    return state.current_page_details.page_status === 'Demo';
  },
  current_role(state, getters, rootState) {
    console.log('rootState', rootState)
    if (!rootState.current_page.cats.roles || !getters.currentPage)
      return {}
    return rootState.current_page.cats.roles.find(rol=>
      rol.name === getters.currentPage.account_role)
  },

};

const mutations = {
  SET_PAGE_DETAILS(state, [data, section=false]) {
    if (section)
      state.current_page_details[section] = data;
    else
      state.current_page_details = data;
  },
  UPDATE_PAGE_DETAILS(state, data) {
    Object.assign(state.current_page_details, data);
  },
  SET_IS_LOADING_PAGE(state, is_loading) {
    state.is_loading_page = is_loading
  },  
  SET_HAS_INITIAL_COLLECT(state, bool) {
    state.has_initial_collect = bool
  },
  SET_QUESTIONS(state, questions) {
    state.initial_collect_questions = questions
  },
  SET_SNACK(state, snack) {
    state.snack = snack
  },

};

const actions = {
  SET_HEADER({state}){
    let token = JwtService.getToken()
    if (token){
      ApiService.defaults.headers.common['Authorization'] = `Token ${token}`;
    }
  },  
  //user
  NOTIFY_USER({commit}, text) {
    commit("SET_SNACK", text)
  },
  //page
  CHECK_ADMIN({commit, dispatch}, pages) {
    let current_pid = router.currentRoute.params.pid;
    let found_page = pages.find(x => x.pid == current_pid)
    if (!!found_page){
      let page_status= found_page.page_status
      if (page_status == 'Demo' || page_status == 'Linked'){
        commit("SET_PAGE_DETAILS", [found_page])
        commit("SET_IS_LOADING_PAGE", false)
      } else {
        return dispatch("REDIRECT_MANAGE",`La página es administrada por 
          el usuario, pero en el status: ${page_status}`)
      }
    }else{
      return dispatch("REDIRECT_MANAGE", `La página no es administrada 
        por el usuario`)
      //Ir a 404, con mensaje de que la página no existe o no tiene acceso al contenido.      
    }
  },
  REDIRECT_MANAGE({dispatch}, error_msg){
    console.error('error: ',error_msg)
    dispatch("NOTIFY_USER", error_msg);
    return router.push({name: `ManagePages`})
  },
  // Ricardo: ¿Esta función no debería ser cambiada a un GET_PAGE_SECTION (page_configuration)?
  GET_PAGE_DETAILS({commit, dispatch}) {
    return new Promise((resolve) => {
      dispatch("SET_HEADER")
      ApiService.get(`/page/${pid()}/`)
        .then(({data}) => {
          commit("UPDATE_PAGE_DETAILS", data)
          return resolve(data)
        })
        .catch(err =>{
          console.error(err)
          resolve(err)
        })
    })
  },  
  GET_PAGE_SECTION({commit, dispatch}, [section]) {
    return new Promise(resolve => {
      dispatch("SET_HEADER")
      ApiService.get(`/page/${pid()}/${section}/`)
        .then(({data}) => {
          commit("UPDATE_PAGE_DETAILS", data);
          commit("UPDATE_PAGE_DETAILS", {last_section:section});
          return resolve(data)
        })
        .catch(err => resolve(err))
    })
  },
  UPDATE_CONFIGURATION({commit, dispatch}, config_details) {
    return new Promise(resolve => {
      dispatch("SET_HEADER")
      ApiService.put(`/page/${pid()}/`, config_details)
        .then(({data})=>{
          resolve(data)
        })
    })
  },
  CREATE_DEMO_PAGE({commit, dispatch}, title) {
    return new Promise(resolve => {
      dispatch("SET_HEADER")
      ApiService.post('/page/', title)
        .then(({data}) => {
          commit("SET_PAGE_DETAILS", [data])
          return resolve(data)
        })
    })
  },
  TRANSFER_DEMO_PAGE({commit, dispatch}, [demo_id, new_pid]) {
    commit("SET_IS_LOADING_PAGE", true)
    dispatch("SET_HEADER")
    return new Promise(resolve => {
      ApiService.put(`/page/${pid(demo_id)}/`, {pid: new_pid})
        .then(({data})=>{
          commit("SET_PAGE_DETAILS", [data])
          commit("UPDATE_PAGE_DETAILS", {transferred:true})
          dispatch("GET_PAGE_SECTION", ['home', new_pid]).then(()=>{
            router.replace({name: 'PageHome', params: { pid: new_pid }})
            commit("SET_IS_LOADING_PAGE", false)
            return resolve(data)
          })
        })
    })
  },
  //funnnel
  FETCH_FUNNEL_DETAILS({commit, dispatch}, given_pid=false) {
    return new Promise(resolve => {
      dispatch("SET_HEADER")
      ApiService.get(`/page/${pid(given_pid)}/funnel-init/`)
        .then(({data})=>{
          resolve(data)
        })
    })
  },
  FETCH_WEEKS({commit, dispatch}, given_pid=false) {
    return new Promise(resolve => {
      dispatch("SET_HEADER")
      ApiService.get(`/change/weeks/`)
        .then(({data})=>{
          resolve(data)
        })
    })
  },
  FETCH_FUNNEL_CHANGE({commit, dispatch}, week) {
    return new Promise(resolve => {
      dispatch("SET_HEADER")
      base_compl = week ? `week/${week}/` : ''
      ApiService.get(`change/funnel/${base_compl}`)
        .then(({data})=>{
          resolve(data)
        })
    })
  },
  //change
  GET_PETITION({commit, dispatch}) {
    return new Promise(resolve => {
      dispatch("SET_HEADER")
      ApiService.get(`/change/petition/${petition_id}/change_request`)
        .then(({data})=>{
          resolve(data)
        })
    })
  },
  UPDATE_PETITION({commit, dispatch}, params) {
    return new Promise(resolve => {
      dispatch("SET_HEADER")
      ApiService.post(`/change/petition/${petition_id}/change_request/`, params)
      ApiService.get(`/change/weeks/`)
        .then(({data})=>{
          resolve(data)
        })
    })
  },
  PETITION_CHANGED({commit, dispatch}) {
    return new Promise(resolve => {
      dispatch("SET_HEADER")
      ApiService.post(`/change/petition/${petition_id}/change_request/done/`)
        .then(({data})=>{
          resolve(data)
        })
    })
  },
};

export default {
  state,
  actions,
  mutations,
  getters
}