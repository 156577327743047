<script>
import PeriodViz from "@/components/page/borde/PeriodViz.vue";
import {mapActions, mapState} from "vuex";

export default {
  name: "BordeScore",
  components: {PeriodViz},
  data() {
    return {
      measure_data: null,
      simple_measures: [
        "borde_score",
        // "quality",
        // "affinity",
      ]
    };
  },
  computed: {
    ...mapState({
      chambers: state => state.brd.chambers,
      measures: state => state.brd.measures,
    }),
    main_measures(){
      if (!this.measures) return []
      return this.measures.filter(m=> this.simple_measures.includes(m.name))
    },
    periods() {
      return this.measure_data.periods.map(period => {
        const start_year = period.start_date.split('-')[0]
        const end_year = period.end_date.split('-')[0]
        period.years = `${start_year}-${end_year}`
        return {
          chamber: this.chambers.find(chamber => chamber.id === period.chamber),
          id: period.id,
          name: period.name,
          years: period.years,
        }
      })
    },
  },
  created() {
    this.fetch_borde_cats().then(()=>{
      this.retrieveMeasure('borde_score')
    })
  },
  methods: {
    ...mapActions({
      fetch_borde_cats: 'GET_BORDE_CATS',
      getMeasure: 'GET_MEASURE',
    }),
    retrieveMeasure(measure){
      this.getMeasure(measure).then(res=>{
        this.measure_data = res
        console.log("this.measure_data", this.measure_data)
        this.redrawAllMeasureViz()
      })
    },
    redrawAllMeasureViz(){
      this.$nextTick(()=>{
          // this.$refs.period_viz.forEach(viz=>
          //   viz.buildCharts())
          this.$refs.period_viz.buildCharts()
      })
    },
  },
}
</script>

<template>
    <v-card-text v-if="measure_data">
<!--      <div v-for="measure in main_measures" :key="measure.id">-->
        <h3 class="text-h5 mb-3" v-if="false">Borde Score</h3>
        <PeriodViz
          ref="period_viz"
          :measure_data="measure_data"
          measure="borde_score"
        />
<!--      </div>-->
    </v-card-text>
</template>

<style scoped lang="scss">

</style>