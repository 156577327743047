<script>
export default {
  name: "HowItWorks",
  data(){
    return {
      ways: [
        {
          title: 'Envía',
          subtitle: 'preguntas e información a tus miembros',
          icon: 'fa-paper-plane',
          size: 36,
        },
        {
          title: 'Recibe',
          subtitle: 'insumos para la toma de decisiones',
          icon: 'move_to_inbox',
          size: 40,
        },
      ]
    }
  }
};
</script>

<template>
  <v-row justify="center" align="center" class="mt-4 mx-n1 mx-sm-n3">
    <v-col
      v-for="(way, idx) in ways"
      :key="way.title"
      cols="12"
      :order="idx === 0 ? 1 : 3"
      align="center"
      sm="5"
      md="4"
    >
      <v-card class="pa-4" elevation="4">
        <v-icon :size="way.size" color="secondary" class="py-4">
          {{way.icon}}
        </v-icon>
        <div class="text-h4 primary--text font-weight-bold" dark>
          {{way.title}}
        </div>
        <div class="text-h5 grey--text text--darken-1">
          {{way.subtitle}}
        </div>
      </v-card>
    </v-col>
    <v-col order="2" cols="1" align="center" v-if="$vuetify.breakpoint.smAndUp">
      <v-icon size="30" color="secondary">fa-exchange-alt</v-icon>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
</style>