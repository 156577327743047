<template>
  <v-card class="py-3 px-2" :class="responsiveCardWidth">
    <v-icon 
      size="52"
      class="py-3"
      color="secondary"
    >{{card_information['icon']}}</v-icon>
    <h1 class="mt-3">{{card_information['title']}}</h1>
    <div class="text-h5">
      {{card_information['details']}}
    </div>
  </v-card>
</template>

<script>
export default {
  name: "CycleInformationCard",
  props: {
    card_index: {
      type: Number
    },
    card_information: {
      type: Object,
      required: true
    }
  },
  computed: {
    responsiveCardWidth() {
      return this.$vuetify.breakpoint.smAndUp
        ? 'card-width' 
        : ''
    }
  }
};
</script>