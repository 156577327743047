<script>
import {mapState} from "vuex";
import * as d3 from "d3";
// import d3Parliament from "/src/
import d3Parliament from "./parliament.js";
import parties from "./parties.js";

export default {
  name: "ChamberViz",
  props: {
    initiative: {
      type: Object,
      required: true
    },
    position: {
      type: Object,
      required: true
    },
    final_positions: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      logo_pac: require("@/assets/brand/logo_pac.png")
    };
  },
  computed: {
    ...mapState({
      chambers: state => state.brd.chambers,
      positions: state => state.brd.positions,
      political_parties: state => state.brd.political_parties,
    }),
    // final_positions: (vm) => vm.positions.filter(pos=>pos.name !== 'neutral'),
  },
  mounted() {
    // this.$nextTick(()=>{
    //   this.buildCharts()
    // })
  },
  methods: {
    buildVotes() {
      // console.log("pol parties", this.political_parties)
      // console.log("final_positions", this.final_positions)
      let positions_obj = this.final_positions.reduce((obj, position)=>{
        obj[`count_${position.name}`] = 0
        obj[position.name] = 0
        return obj
      }, {})
      // console.log("positions_obj", positions_obj)
      // console.log("chambers 0", this.chambers)
      let initial_obj = this.chambers.reduce((arr, chamber)=>{
        arr[chamber.id] = {
          ...chamber,
          ...{ parties: this.political_parties.reduce((obj, party)=>{
            obj[party.id] = {
              ...party,
              ...positions_obj,
              ...{
                seats: [],
                seats_count: 0,
                count_final: 0
              }
            }
            return obj
          }, {})}
        }
        return arr
      }, {})
      // console.log("initial_obj", initial_obj)
      let chambers = this.initiative.votes.reduce((obj, vote)=>{
        const position = vote.position === 'neutral' ? 'ausent' : vote.position
        // obj[vote.political_party][vote.position] = vote.count
        obj[vote.chamber].parties[vote.political_party][position] += vote.count
        // let seats = []
        for (let i = 0; i < vote.count; i++){
          // seats.push(vote)
          obj[vote.chamber].parties[vote.political_party].seats.push(vote)
        }
        obj[vote.chamber].parties[vote.political_party].seats_count += vote.count
        return obj
      }, initial_obj)
      // console.log("chambers 1", chambers)
      // return chambers
      chambers = Object.entries(chambers).reduce((obj, [chamber_id, chamber])=>{
        obj[chamber_id] = Object.entries(chamber.parties).map(([party, votes])=>{
          let party_obj = this.political_parties.find(p=>p.id === Number(party))
          return {
            ...party_obj,
            ...votes
          }
        })
        return obj
      }, {})
      // console.log("chambers 2", chambers)
      return chambers
    },
    buildCharts(){
      let vm = this
      // let margin = {top: 130, right: 20, bottom: 10, left: 20},
      //     width = 460,
      //     height = 230,
      //     height_response = 30;
      let margin = {top: 0, right: 20, bottom: 10, left: 20},
          width = 800,
          height = 675;
      const white = 'rgba(255,255,255,0.66)'

      let all_votes = this.buildVotes()
      console.log("all_votes", all_votes)
      console.log("final_positions", vm.final_positions)
      const symbols = {
        // 'agree': {symbol: d3.symbolCross, size_sm: 60, size_lg: 200},
        'agree': d3.symbolPlus,
        'disagree': d3.symbolX,
        // 'neutral': {symbol: d3.symbolDiamond, size_sm: 60, size_lg: 200},
        'ausent': d3.symbolCircle
      }

      // const getSymbolData = (position, is_small) => {
      //   // const has_vote = position.name !== 'ausent'
      //   return {
      //     // size: is_small ? 40 : 120,
      //     size: is_small ? 64 : 200,
      //     // stroke_width: is_small ? 2 : 3,
      //     stroke_width: is_small ? 3 : 5,
      //     d3_symbol: symbols[position.name]
      //   }
        // response["d3_symbol"] = symbols[position.name]
        // response["size"] = is_small
        //     ? has_vote ? 40 : 66
        //     : has_vote ? 120 : 260
        // response["stroke_width"] = is_small
        //     ? has_vote ? 2 : 1.2
        //     : has_vote ? 3 : 2
        // return response
      // }

      const drawSymbol = (element, pos, is_small, class_prev) => {
        // console.log("class_prev", class_prev)
        // console.log("element", element)
        // console.log("pos", pos)
        if (!pos.name)
          pos = pos.datum()
        const size = is_small ? 64 : 240
        const stroke_width = is_small ? 3.5 : 6
        const d3_symbol = d3.symbolX
        // const symbol = getSymbolData(pos, is_small)
        element
          .attr('d', d3.symbol().type(d3_symbol).size(size))
          .attr('stroke-width', stroke_width)
          .attr('class', `${class_prev}-${pos.name}`)
          .attr('fill', 'transparent')
          .attr('stroke', d => {
            // if (pos.name === vm.position.name)
            //   return white
            const color_viz = class_prev === 'cross'
              ? `${d.party.color_viz}`
              : `${d.color_viz}`
            return `${color_viz}C8`
          })

      }

      this.chambers.forEach(chamber=>{
        const is_dip = chamber.name === 'Cámara de Diputados'
        // if (!is_dip){
        //   margin.top = 160
        // }
        let votes = all_votes[chamber.id]
        votes = votes.filter(party=>party.seats_count)
        console.log(`votes for ${chamber.name} -- ${vm.position.name}\n`, votes)
        let current_id = `#viz-${vm.position.name}-${chamber.id}`
        // const is_empty = d3.select(`${current_id} > svg`).empty()
        // if (!is_empty)
        d3.select(`${current_id}`).selectAll("*").remove()
        let svg = d3.select(current_id)
          .append("svg")

        svg
          .attr("viewBox", [-margin.left, -margin.top,
              width + margin.right + margin.left,
              height + margin.bottom + margin.top])

        let legend_group = svg
          .append("g")
          .attr("transform", `translate(0, ${-margin.top + 5})`)

        let parliament = d3Parliament()
          .width(width)
          // .innerRadiusCoef(0.4)
          .innerRadiusCoef(is_dip ? 0.35 : 0.45)

        const other_positions = vm.final_positions.filter(
            pos => pos.name !== vm.position.name)
        const all_positions = [vm.position, ...other_positions]
        // console.log("all_positions", all_positions)

        svg.datum(votes)
          .call(parliament)
          .selectAll('circle.seat')
          .attr('stroke', d => d.party.color_viz)
          .attr('stroke-width', is_dip ? 2 : 4)
          .attr('fill', d => {
            return d.data.position === 'agree'
                ? `${d.party.color_viz}CB`
                : 'transparent'
          })


        let all_circles = svg.selectAll('circle.seat')
          .nodes()
          .map(function(d){
            const circle = d3.select(d)
            return circle.datum()
            // return {
            //   data: circle.datum(),
            // }
          })

        let parliament_g = svg
          .selectAll('g.parliament')

        console.log("all_circles", all_circles)
        // let real_circles = parliament_g.selectAll('circle.seat')
        // console.log("real_circles", real_circles)


        let disagree = all_positions.find(pos=> pos.name === 'disagree')
        // all_positions.forEach(position=>{
        //   parliament_g.selectAll(`path.cross-${position.name}`)
        parliament_g.selectAll('path.cross-disagree')
          .data(all_circles)
          .enter()
          .append('path')
          // .filter(d => d.data.data === position.name)
          .filter(d => d.data.position === 'disagree')
          // .call(element => drawSymbol(element, position, is_dip, 'cross'))
          .call(element => drawSymbol(element, disagree, is_dip, 'cross'))
          // .attr('d', d3.symbol().type(symbol.d3_symbol).size(symbol.size))
          // .attr('class', d => `cross-${d.data.data}`)
          .attr('transform', d => {
            const cartesian = d.cartesian
            const x = cartesian.x
            const y = -cartesian.y
            return `translate(${x}, ${y})`
          })
            // .attr('stroke-width', symbol.stroke_width)
            // .attr('pointer-events', 'none')
        // })

            // .on('mouseover', function (d) {
            //   d3.select(this)
            //       .attr('stroke-width', is_dip ? 5 : 7)
            //       .attr('stroke-opacity', 1)
            // })
            // .on('mouseout', function (d) {
            //   d3.select(this)
            //       .attr('stroke-width', is_dip ? 3 : 5)
            //       .attr('stroke-opacity', .7)
            // })

        // console.log("all_circles", all_circles)


        const height_rows = 38
        const width_party = width / votes.length
        const margin_logos = 36 + width_party
        const left_leg = 60

        parties(legend_group, votes, width_party)

        const same_vote_count = votes.reduce((sum, party) => {
          return sum + party[vm.position.name] || 0
        }, 0)

        let real_idx = 0
        vm.final_positions.forEach((pos, idx) =>{
          // const sum_votes = votes.reduce((sum, party) => {
          //   return sum + party[pos.name] || 0
          // }, 0)
          // if (sum_votes){

          // legend_group
          //   .append('text')
          //   .text(pos.alternative_name)
          //   .attr('x', 0)
          //   .attr('font-weight', pos.name === vm.position.name
          //       ? 'bold'
          //       : 'normal')
          //   .attr('font-size', 8)
          //   .attr('y', idx * height_rows + 50)
          //   .attr('font-size', 10)

          if (pos.name === vm.position.name){
            legend_group
              .append('rect')
              .attr('x', 0)
              .attr('y', real_idx * height_rows + margin_logos - height_rows / 2)
              .attr('width', width)
              .attr('height', height_rows * 2)
              .attr('rx', 10)
              .attr('fill', 'rgba(196,196,196,0.62)')

            legend_group
              .append('text')
              .attr('x', width / 2)
              .attr('y', margin_logos + height_rows * real_idx + 3)
              .attr('text-anchor', 'middle')
              .attr('alignment-baseline', 'middle')
              .attr('font-size', 26)
              .attr('font-weight', 'bold')
              .attr('font-family', 'Montserrat')
              .attr('letter-spacing', '0.35em')
              .attr('fill', 'rgb(255,255,255)')
              .text(`${same_vote_count} VOTARON COMO TÚ (${pos.alternative_name.toUpperCase()})`)

            real_idx++
          }

          // const fill_circles = pos.name === 'agree'
          //     ? `${pos.color_viz}CB`
          //     : 'transparent'
          legend_group
            .selectAll(`circle.${pos.name}`)
            .data(votes)
            .enter()
            .append('circle')
            .attr('class', pos.name)
            .attr('cx', (d, i) => i * width_party + 20)
            .attr('cy', real_idx * height_rows + margin_logos)
            .attr('r', 14)
            // .attr('opacity', d => d[pos.name] ? 1 : 0.2)
            .attr('opacity', d => d[pos.name] ? 1 : 0.2)
            .attr('stroke', d => d.color_viz)
            .attr('stroke-width', 3.5)
            // .attr('stroke-opacity', .7)
            // .attr('fill', fill_circles)
            .attr('fill', d => pos.name === 'agree'
                ? `${d.color_viz}CB`
                : 'transparent')

          legend_group
            .selectAll(`text.${pos.name}`)
            .data(votes)
            .enter()
            .append('text')
            .attr('class', pos.name)
            .text(d => d[pos.name] || 0)
            .attr('x', (d, i) => i * width_party + 40)
            .attr('y', real_idx * height_rows + margin_logos + 6)
            .attr('opacity', d => d[pos.name] ? 1 : 0.2)
            .attr('font-size', 20)
            .attr('font-weight', 'bold')
            .attr('font-family', 'Roboto')
            .attr('fill', d => d.color_viz)
            .attr('text-anchor', 'start')
            .attr('alignment-baseline', 'middle')
            .attr('dominant-baseline', 'middle')
          // }
          if (pos.name === 'disagree'){
            legend_group
              .selectAll('path.legend-disagree')
              .data(votes)
              .enter()
              .append('path')
              // .filter(d => d.disagree)
              .call(element => drawSymbol(element, disagree, false, 'legend'))
              .attr('transform', (d, i) => {
                const x = i * width_party + 20
                const y = real_idx * height_rows + margin_logos
                return `translate(${x}, ${y})`
              })
              .attr('opacity', d => d[pos.name] ? 1 : 0.2)
          }

          real_idx++
        })

        const margin_leg = is_dip ? 10 : 30

        parliament_g
          .selectAll('circle.leg2')
          .data(vm.final_positions)
          .enter()
          .append('circle')
          .attr('class', 'leg2')
          .attr('cx', -left_leg)
          .attr('cy', (d, i) => i * height_rows + margin_leg)
          .attr('r', 14)
          .attr('stroke', d => d.color_viz)
          .attr('stroke-width', 3.5)
          // .attr('fill', d => vm.position.name === d.name
          .attr('fill', d => d.name === 'agree'
              ? `${d.color_viz}CB`
              : 'transparent')

        parliament_g
          .selectAll(`path.legend2`)
          .data(vm.final_positions)
          .enter()
          .append('path')
          .each(function(d, idx){
            if (d.name !== 'disagree')
              return
            // .each(element => drawSymbol(element, element, false, 'legend2 '))
            let element = d3.select(this)
            drawSymbol(element, d, false, 'legend2 ')
            const y = idx * height_rows + margin_leg
            element
              .attr('transform', `translate(${-left_leg}, ${y})`)
          })

        parliament_g
          .selectAll('text.leg2')
          .data(vm.final_positions)
          .enter()
          .append('text')
          .attr('class', 'leg2')
          .text(d => d.name === 'ausent'
              ? 'SIN VOTO'
              : d.alternative_name.toUpperCase())
          // .attr('x', 0)
          // .attr('y', (d, i) => i * height_rows + 6)
          .attr('font-size', 22)
          .attr('font-family', 'Montserrat')
          .attr('font-weight', 'bold')
          .attr('fill', d => d.color_viz)
          .attr('text-anchor', 'start')
          .attr('alignment-baseline', 'middle')
          .attr('transform', (d, i) => {
            const y = i * height_rows + margin_leg
            return `translate(${-left_leg + 24}, ${y})`
          })


        // legend_group.append("text")
        //     .attr("x", width - 20)
        //     .attr("y", 60)
        //     .attr("text-anchor", "end")
        //     .attr("font-size", 16)
        //     .text("Cámara de ")
        //
        // legend_group.append("text")
        //     .attr("x", 40)
        //     .attr("y", height - 80)
        //     .attr("text-anchor", "start")
        //     .attr("font-weight", "500")
        //     .attr("font-size", 17)
        //     .text(`${chamber.name}`)

      })
    },
  },
}
</script>


<template>
  <v-card elevation="4" class="pa-3" style="max-width: 500px">
    <div
      class="text-center no-wrap font-weight-bold pt-0 mb-5 mt-1"
      style="color: #ff3e61; font-size: 1.2em; font-family: 'Montserrat';"
    >
      {{initiative.title}}
    </div>
    <v-card-subtitle class="text-center" v-if="false">
      <span class="font-weight-bold">
        {{initiative.title}}
      </span>
    </v-card-subtitle>
    <v-row
      v-for="chamber in chambers"
      :key="chamber.id"
    >
      <v-col cols="12" class="py-0">
        <v-sheet
          color="#334050"
          class="pa-0 white--text text-center text-h6"
        >
          <b style="font-family: 'Montserrat';">
            {{chamber.name.toUpperCase()}}
          </b>
        </v-sheet>
      </v-col>
      <v-col cols="12" class="pt-0">
        <div
          :id="`viz-${position.name}-${chamber.id}`"
          _class="mt-3"
        >

        </div>
      </v-col>
      <v-col cols="10" v-if="false">
        <v-card-subtitle class="text-center mt-2 text-h6">
          <div class="black--text mb-3">
            Iniciativa: {{`"${initiative.title}"`}}
          </div>
          Legisladores que votaron en el mismo sentido que tú
        </v-card-subtitle>
      </v-col>
      <v-col cols="2" v-if="false">
        <v-img
          :src="logo_pac"
          :alt="`Logo`"
          :width="120"
          :height="120"
          class="mx-auto"
        ></v-img>
      </v-col>
    </v-row>
  </v-card>
</template>
<style scoped lang="scss">
  // import montserrat
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
</style>