<script>
import LandingNav from '@/components/landing/LandingNav.vue'
import HeaderSection from '@/components/landing/HeaderSection.vue'
import HowItWorksSection from '@/components/landing/HowItWorksSection.vue'
import ChatbotExplanationSection from '@/components/landing/ChatbotExplanationSection.vue'

import CycleInformationListSection from '@/components/landing/CycleInformationListSection.vue'
import ConnectNowSection from '@/components/landing/ConnectNowSection.vue'
import AboutYeekoSection from '@/components/landing/AboutYeekoSection.vue'
import ClientsSection from '@/components/landing/ClientsSection.vue'
import FooterSection from '@/components/landing/FooterSection.vue'

export default {
  name: "LandingHolder",
  components: {
    LandingNav,
    HeaderSection,
    HowItWorksSection,
    ChatbotExplanationSection,
    CycleInformationListSection,
    ConnectNowSection,
    AboutYeekoSection,
    ClientsSection,
    FooterSection
  },
  mounted(){
    console.log("Landing")
    this.$store.dispatch("CHECK_AUTH_SIMPLE")
    // this.$store.dispatch("LOAD_PLUGIN")
  },
};

</script>

<template>
  <v-app id="app-landing">
    <LandingNav/>
    <v-main>
      <v-container pa-0 fluid>
        <HeaderSection/>
        <HowItWorksSection/>
        <ChatbotExplanationSection/>
        <CycleInformationListSection />
        <ConnectNowSection/>
        <!-- <AboutYeekoSection v-if="false"/> -->
        <ClientsSection/>
        <FooterSection class="text-center"/>
        <div id="whatsapp-button"></div>
        <div id="fb-root"></div>
        <div id="fb-customer-chat" class="fb-customerchat"></div>

      </v-container>
    </v-main>
  </v-app>
</template>

<style lang="scss">
#app-landing{
  margin: auto;
  max-width: 1040px;
}
</style>