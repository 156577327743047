<script>
// Helpers
import { mapState } from "vuex";
import { FULL_LOGIN, EMBEDDED_SIGNUP } from "@/common/config";
import {
  getFbLoginStatus, fbLogout, fbLoginPartner, fbLoginBusiness
} from "./helpers.js";

// Resources

export default {
  name: "FacebookLogin",
  props: {
    locale: {
      type: String,
      required: false,
      default: "es_LA"
    },
    logoutLabel: {
      type: String,
      default: "Sesión iniciada"
    },
    loginLabel: {
      type: String,
      default: "Inicia Sesión con Facebook"
    },
    specials_permissions: {
      type: Boolean,
      required: false,
      default: false
    },
    isUpperCase: {
      type: Boolean,
      required: false,
      default: false,
    },
    login_checked: {
      type: Boolean,
      required: false,
      default: true,
    },
    is_embedded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isConnected: false,
      attempts_login: 0,
      loading: false,
      loading_login: false,
    };
  },
  computed: {
    ...mapState({
      fb_loaded: state => state.auth.fb_loaded,
      is_logging_in: state => state.auth.is_logging_in,
    }),
    getButtonText() {
      switch (this.isConnected) {
        case true:
          return this.isUpperCase
            ? this.logoutLabel.toUpperCase()
            : this.logoutLabel;

        case false:
          return this.isUpperCase
            ? this.loginLabel.toUpperCase()
            : this.loginLabel;
        default:
          return "this is default";
      }
    },
  },
  created() {
    // console.log("created", this.$route);
    const code = this.$route.query.code;
    if (code) {
      this.loading_login = true;
      this.onSignInSuccess({ status: "connected", authResponse: { code: code } });
    }
  },
  methods: {
    buttonClicked() {
      this.$emit("click");
      if (this.isConnected)
        this.logout()
      else
        this.wantConnect()
    },
    wantConnect() {
      this.attempts_login++
      if (this.fb_loaded)
        this.login()
      else if (this.attempts_login < 10){
        setTimeout(() => {
          this.wantConnect();
        }, 500)
      }
      else
        console.error("Facebook SDK not loaded");

    },
    onSignInSuccess(responseFb) {
      this.$store.dispatch('LOGIN_FROM_FACEBOOK', responseFb).then(() => {
        this.loading_login = false;
      });
    },
    login() {
      this.$store.dispatch("LOAD_FB_SDK", false);
      // fbLoginSimple().then(response => {
      // const config_id = "521588170860003"
      // fbLoginPartner(config_id).then(response => {
      // console.log("login called");
      const login_config = this.is_embedded ? EMBEDDED_SIGNUP : FULL_LOGIN;
      fbLoginBusiness(login_config).then(response => {
        console.log("response", response);
        this.isConnected = response.status === "connected";
        this.onSignInSuccess(response);
      });
    },
    logout() {
      fbLogout().then(response => {
        this.isConnected = false;
        this.$emit("logout", { response, FB: window.FB });
      });
    }
  }
};
</script>

<template>
  <v-btn
    color="#4267B2"
    class="Aligner-item facebook-button"
    :loading="is_logging_in || loading_login"
    :disabled="!login_checked"
  >
    <div class="facebook-login">
      <button @click="buttonClicked">
        <img v-if="!isConnected" src="../../../assets/facebook-logo2.svg">
        {{getButtonText}}
      </button>
    </div>
  </v-btn>
</template>

<style scoped>
.facebook-login {
  box-sizing: border-box;
  padding-left: 15px;
}

.facebook-login * {
  box-sizing: inherit;
}

.facebook-login button {
  border: none;
  color: #fff;
  position: relative;
  line-height: 34px;
  /* background-color: #4267b2; */
}

.facebook-login .spinner {
  left: 5px;
  width: 30px;
  height: 90%;
  display: block;
  border-radius: 50%;
  position: absolute;
  border: 5px solid #f3f3f3;
  border-top-color: #3498db;
  animation: facebook-login-spin 2s linear infinite;
}

.facebook-login img {
  position: absolute;
  top: 6px;
  left: -25px;
  width: 20px;
}


@keyframes facebook-login-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>