<script>
import authMixin from "./authMixin";

export default {
  name: 'RegisterMail',
  mixins: [authMixin],  
  props: {
    preload_data: {
      type: Object,
      required: false,
    },
  },
  data(){
    return {
      register_data: false,
      new_name: undefined,
      new_email: undefined,
      password: undefined,
      password_repeat: '',
    }
  },
  computed:{
    passwordConfirmationRule() {
      return this.password === this.password_repeat || 'Las contraseñas deben ser iguales'
    },
    name:{
      get(){
        return this.preload_data && !this.new_name
          ? `${this.preload_data.first_name} ${this.preload_data.last_name}`
          : this.new_name        
      },
      set(name){
        this.new_name = name
      }
    },
    email:{
      get(){
        return this.preload_data && !this.new_email
          ? this.preload_data.email
          : this.new_email        
      },
      set(name){
        this.new_email = name
      }
    },
  },

  methods:{
    register() {
      if(this.$refs.register_ref.validate()){
        let content = {
          email: this.email, 
          password : this.password,
          name: this.name
        }
        this.$store.dispatch('REGISTER_MAIL', content)
          .then(res=>{
            this.$store.dispatch('YEEKO_LOGIN_MAIL')
          })
          .catch(err =>{console.log(err)}
          );
      }
    },
  },
}
</script>

<template>
  <v-form v-model="register_data" ref="register_ref" lazy-validation>
    <v-card-text class="pb-0 px-0 px-sm-2">
      <v-text-field
        label="Nombre Completo"
        name="name"
        prepend-icon="person"
        type="text"
        outlined
        v-model="name"
        :rules="[rules.user_name]"
      ></v-text-field>
      <v-text-field
        label="Correo electrónico"
        name="email"
        outlined
        prepend-icon="mail"
        type="email"
        v-model="email"
        :rules="[rules.email, rules.user_name]"
      ></v-text-field>
      <v-text-field
        name="password"
        id="password"
        outlined
        label="Tu contraseña"
        v-model="password"
        type="password"
        prepend-icon="lock"
        :rules="[rules.required, rules.min]"
        hint="Escribe una contraseña segura"
      ></v-text-field>
      <v-text-field
        name="rePassword"
        id="password2"
        outlined
        v-model="password_repeat"
        type="password"
        @keyup.enter="register"
        prepend-icon="enhanced_encryption"
        :rules="[rules.required, rules.min, passwordConfirmationRule]"
        label="Vuelve a escribir tu contraseña"
        hint="Debe ser idéntica"
      ></v-text-field>
    </v-card-text>
    <v-card-actions class="pt-0">
      <v-spacer></v-spacer>
      <v-btn
        color="success" 
        @click="register"
        :disabled="!register_data"
        class="px-8"
      >Crear Cuenta</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-form>
</template>