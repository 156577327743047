import * as d3 from 'd3';

let mixinLegend = {
  methods: {
      legend({
        color,
        title,
        tickSize = 6,
        width = 320,
        height = 44 + tickSize,
        marginTop = 6,
        marginRight = 0,
        marginBottom = 21 + tickSize,
        marginLeft = 0,
        fontSize = 10,
        fontFamily = "Roboto",
        ticks = width / 64,
        tickFormat,
        tickValues,
        element_id = "#shortage-viz",
      } = {}) {

        // const svg = d3.select('#shortage-viz')
        // let svg = d3.select("#shortage-viz > svg")
        let svg = d3.select(`${element_id} > svg`)

        function buildRect(color, n=64){
          //const interpolate = d3.interpolateCool
          let grad = svg
            .selectAll("defs")
            .data([0])
            .join("defs")
            .append("linearGradient")
            .attr("id", "gradient-0")

          const fraccs = d3.range(n+1).map(i => i/n)

            grad
              .selectAll("stop")
              .data(fraccs)
              .join(
                enter => enter
                  .append("stop")
                    .attr("stop-color", (d)=>
                      //d3.rgb(interpolate(frac)).hex()
                      d3.rgb(color(d)).hex()
                    )
                    .attr("offset", (d)=> `${d*100}%`),
                update => update
                  .attr("stop-color", d=> d3.rgb(color(d)).hex())
                  .attr("offset", (d)=> `${d*100}%`),
                exit => exit.remove()
              )

          // for (var i = 0; i < n+1; i++) {
          //   var frac = (i)/n
          //   grad.append("stop")
          //     .attr("offset",  `${frac*100}%`)
          //     .attr("stop-color", ()=>
          //       //d3.rgb(interpolate(frac)).hex()
          //       d3.rgb(color(frac)).hex()
          //     )
          // }
        }

        let tickAdjust = g => g.selectAll(".tick line")
          .attr("y1", marginTop + marginBottom - height);
        let x;

        // Continuous
        if (color.interpolate) {
          console.log("Continuous")
          const n = Math.min(color.domain().length, color.range().length);

          x = color.copy().rangeRound(d3.quantize(d3.interpolate(
                                        marginLeft, width - marginRight), n));

          buildRect(color.copy().domain(d3.quantize(d3.interpolate(0, 1), n)))

          //buildRect(color)
          svg
            .selectAll("rect")
            .data([0])
            .join("rect")
              .attr("x", marginLeft)
              .attr("y", marginTop)
              .attr("width", width - marginLeft - marginRight)
              .attr("height", height - marginTop - marginBottom)
              .style('fill', 'url(#gradient-0)')

        }

        // Sequential
        else if (color.interpolator) {
          // console.log("Secuencial")
          x = Object.assign(color.copy()
              .interpolator(d3.interpolateRound(marginLeft, width - marginRight)),
              {range() { return [marginLeft, width - marginRight]; }});

          buildRect(color.interpolator())

          svg
            .selectAll("rect.leg2")
            .data([0])
            .join("rect")
              .attr("class", "leg2")
              .attr("x", marginLeft)
              .attr("y", marginTop)
              .attr("width", width - marginLeft - marginRight)
              .attr("height", height - marginTop - marginBottom)
              .style('fill', 'url(#gradient-0)')

          // scaleSequentialQuantile doesn’t implement ticks or tickFormat.
          if (!x.ticks) {
            console.log("no x.thick")
            if (tickValues === undefined) {
              const n = Math.round(ticks + 1);
              tickValues = d3.range(n).map(i =>
                d3.quantile(color.domain(), i / (n - 1)));
            }
            if (typeof tickFormat !== "function") {
              console.log("tickFormat", tickFormat)
              tickFormat = d3.format(tickFormat === undefined ? ",f" : tickFormat);
            }
          }
          else{
            if (typeof tickFormat !== "function") {
              if (tickFormat === undefined)
                tickFormat = i => d3.format(".0%")(i)
              else
                tickFormat = d3.format(tickFormat === undefined ? ",f" : tickFormat);
            }
          }
        }


        // Threshold
        else if (color.invertExtent) {
          console.log("Threshold")
          const thresholds
              = color.thresholds ? color.thresholds() // scaleQuantize
              : color.quantiles ? color.quantiles() // scaleQuantile
              : color.domain(); // scaleThreshold

          const thresholdFormat
              = tickFormat === undefined ? d => d
              : typeof tickFormat === "string" ? d3.format(tickFormat)
              : tickFormat;

          x = d3.scaleLinear()
              .domain([-1, color.range().length - 1])
              .rangeRound([marginLeft, width - marginRight]);

          svg.append("g")
            .selectAll("rect.leg")
            .data(color.range())
            .join(
              enter => enter
                .append("rect")
                  .attr("class", "leg")
                  .attr("x", (d, i) => x(i - 1))
                  .attr("y", marginTop)
                  .attr("width", (d, i) => x(i) - x(i - 1))
                  .attr("height", height - marginTop - marginBottom)
                  .attr("fill", d => d),
              update => update
                .attr("x", (d, i) => x(i - 1))
                .attr("y", marginTop)
                .attr("width", (d, i) => x(i) - x(i - 1))
                .attr("height", height - marginTop - marginBottom)
                .attr("fill", d => d),
              exit => exit.remove()
            )
            // .join("rect")
            //   .attr("x", (d, i) => x(i - 1))
            //   .attr("y", marginTop)
            //   .attr("width", (d, i) => x(i) - x(i - 1))
            //   .attr("height", height - marginTop - marginBottom)
            //   .attr("fill", d => d);

          tickValues = d3.range(thresholds.length);
          tickFormat = i => thresholdFormat(thresholds[i], i);
        }

        // Ordinal
        else {
          console.log("Ordinal")
          x = d3.scaleBand()
              .domain(color.domain())
              .rangeRound([marginLeft, width - marginRight]);

          svg.append("g")
            .selectAll("rect")
            .data(color.domain())
            .join("rect")
              .attr("x", x)
              .attr("y", marginTop)
              .attr("width", Math.max(0, x.bandwidth() - 1))
              .attr("height", height - marginTop - marginBottom)
              .attr("fill", color);

          tickAdjust = () => {};
        }

        const legend_ticks = svg
          .select("g.legend_ticks")

        legend_ticks
          .data([0])
          .join(
            enter => enter
              .attr("class", "legend_ticks")
              .attr("transform", `translate(0,${height - marginBottom})`)
              .call(d3.axisBottom(x)
                .ticks(ticks, typeof tickFormat === "string" ? tickFormat : undefined)
                .tickFormat(typeof tickFormat === "function" ? tickFormat : undefined)
                .tickSize(tickSize)
                .tickValues(tickValues))
              .attr("font-size", `${fontSize*.8}pt`)
              .attr("font-family", fontFamily)
              .call(tickAdjust)
              .call(g => g.select(".domain").remove()),
            update => update
              .attr("transform", `translate(0,${height - marginBottom})`)
              .call(d3.axisBottom(x)
                .ticks(ticks, typeof tickFormat === "string" ? tickFormat : undefined)
                .tickFormat(typeof tickFormat === "function" ? tickFormat : undefined)
                .tickSize(tickSize)
                .tickValues(tickValues))
              .attr("font-size", `${fontSize*.8}pt`)
              .attr("font-family", fontFamily)
              .call(tickAdjust)
              .call(g => g.select(".domain").remove()),
            exit => exit.remove()
          )

        legend_ticks
          .selectAll('text.main_text')
          .data([0])
          .join(
            enter => enter
              .append("text")
                .attr("class", "main_text")
                .attr("x", marginLeft)
                .attr("y", marginTop + marginBottom - height - 6)
                .attr("fill", "currentColor")
                .attr("text-anchor", "start")
                .attr("font-weight", "bold")
                .attr("font-size", `${fontSize}pt`)
                .attr("font-family", fontFamily)
                .text(title),
            update => update
              .attr("x", marginLeft)
              .attr("y", marginTop + marginBottom - height - 6)
              .text(title),
            exit => exit.remove()
          )



          // .attr("transform", `translate(0,${height - marginBottom})`)
          // .call(d3.axisBottom(x)
          //   .ticks(ticks, typeof tickFormat === "string" ? tickFormat : undefined)
          //   .tickFormat(typeof tickFormat === "function" ? tickFormat : undefined)
          //   .tickSize(tickSize)
          //   .tickValues(tickValues))
          // .attr("font-size", `${fontSize*.8}pt`)
          // .call(tickAdjust)
          // .call(g => g.select(".domain").remove())
          // .call(g => g.append("text")
          //   .attr("x", marginLeft)
          //   .attr("y", marginTop + marginBottom - height - 6)
          //   .attr("fill", "currentColor")
          //   .attr("text-anchor", "start")
          //   .attr("font-weight", "bold")
          //   .attr("font-size", `${fontSize}pt`)
          //   .text(title));

        // var my_ticks = d3.selectAll('.tick text')
        //   .attr("opacity", (d, i)=> d/color.domain()[1] > .3 && (i%2) ? 0 : 1)
      }

  },

}

export default mixinLegend;
