<template>
  <v-parallax dark :src="background_image" height="300" id="header">
    <v-layout 
      row wrap justify-center 
      align-center 
      class="landing-max-width-section elevation-1 text-center"
    >
      <v-flex xs12 py-0 px-5 mb-5>
        <h1 class="secondary--text">Utilízalo en tu Organización</h1>
        <br/>
        <v-btn color="accent" to="/dashboard/login">
          Comenzar
        </v-btn>
      </v-flex>
      <!-- <v-flex xs12 px-5 py-0> -->
        <!-- <v-btn class="button-facebook mb-3" text disabled>
          <v-icon left size="22" dark>fab fa-facebook-square</v-icon>Conectar a mi página
        </v-btn>
        <br>
        <v-btn class="accent--text" to="/login?demo=true">Probar un demo</v-btn> -->
      <!-- </v-flex> -->
    </v-layout>
  </v-parallax>
</template>

<script>
export default {
  name: "ConnectNow",
  data() {
    return {
      background_image: require("@/assets/brand/useyeeko.webp")
    }
  }
};
</script>

<style lang="scss" scoped>
</style>