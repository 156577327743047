<template>
  <v-app id="app">
    <router-view />
    <v-snackbar v-model="snackbar" bottom :timeout="8000">
      {{ text }}
      <v-btn color="white" outlined @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </v-app>
</template>
<script>
//import { createGACookie } from "@/common/gaHelpers.js"
import { mapState } from "vuex";
export default {
  data() {
    return {
      snackbar: false,
      text: "",
      url: ""
    };
  },
  computed: {
    ...mapState([
      "snack" // Elemento para hacer la mensajeria en el Dashboard
    ])
  },
  mounted() {
    //createGACookie()
    /*ga(function(tracker) {
      console.log(ga.getAll());
    });*/
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "setSnack":
          const msg = state.page_data.snack;
          if (msg !== "") {
            this.snackbar = true;
            this.text = msg;
            this.$store.commit("setSnack", "");
          }
          break;
      }
    });
  },
};
</script>

<style lang="scss">

#title {
  font-size: 1.65rem;
}


//Se moverán cuando copiemos lo de yeeko-web
.button-facebook{
  color: white !important;
  background-color: #4267B2 !important;
}
.facebook-login button{
  color: white;
}
.facebook-icon {
  margin-right: 10px;
  font-size: 1.2rem
}

.messenger-button {
  /*background-color: #0084ff !important;
  color: white !important;*/
  color: #0084ff !important;
}
.no-margin {
  margin: 0 !important;
}

.vertical-buttons {
  flex-direction: column;
}
.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}
#app {
  display: inline-flex;
  width: 100%;
}
.card-margin {
  margin-bottom: 2.5vh;
}
.floating-card {
  margin-top: -50px;
}
.card-padding {
  padding: 1.5%;
}
.internal-padding {
  padding: 20px !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.65s ease-out;
}


.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.card--title{
  font-weight: 600;
  margin-left: 0.5vw;
  font-size: 13pt;
}
.bold-text {
  font-weight: bold;
}

.no-wrap{
  word-break: normal !important;
}

.custom-icon{
  top: 6px;
  width: 24px;
}

.pointer{
  cursor: pointer;
}

</style>