/*eslint-disable*/
import router from '@/router'
import axios from "axios";
import JwtService from "@/common/jwt.service";
import ApiService from "./base";

let request = axios.CancelToken.source();

let pid = (given_pid) => given_pid || router.currentRoute.params.pid

const state = {
  yeekos: [],
  current_yeeko: undefined,
};

const getters = {
};

const mutations = {
  SET_YEEKOS(state, yeekos) {
    state.yeekos = yeekos
  },
  SET_YEEKO(state, yeeko){
    state.current_yeeko = yeeko;
  },
  UPDATE_CURR_YEEKOS(state, [action, yeek]) {
    let is_del = action == 'delete'
    var yeekos = state.yeekos
    let idx = yeekos.findIndex(el => el.id === (is_del ? yeek : yeek.id))
    is_del ? yeekos.splice(idx, 1) : yeekos.splice(idx, 1, yeek)
  },
  ADD_YEEKO(state, yeek) {
    state.yeekos.splice(0, 0, yeek)
  },
  ADD_PROPOSAL(state, proposal) {
    Vue.set(proposal, 'is_new', true)
    state.current_yeeko.proposals.unshift(proposal)
  },
};

const actions = {
  SET_HEADER({state}){
    let token = JwtService.getToken()
    if (token){
      ApiService.defaults.headers.common['Authorization'] = `Token ${token}`;
    }
    //this.$axios.setToken(token, 'Token')
  },
  FETCH_YEEKOS({commit, dispatch}) {
    return new Promise(resolve => {
      dispatch("SET_HEADER")
      ApiService.get(`page/${pid()}/yeeko/?meta_yeeko=true`)
        .then(({data}) => {
          commit('SET_YEEKOS', data.results);
          return resolve(data)
        })
        .catch(err => resolve(err))
    })
  },
  GET_YEEKO({commit, dispatch}, yeeko_id){
    return new Promise (resolve => {
      dispatch("SET_HEADER")
      ApiService.get(`/page/${pid()}/yeeko/${yeeko_id}/`).then(({data})=>{
        //commit("SET_YEEKO", data)
        return resolve(data)
      }).catch((err)=>{
        console.log(err)
      })
    })
  },
  UPDATE_YEEKO({commit, dispatch}, [yeeko_id, params]) {
    return new Promise(resolve => {
      dispatch("SET_HEADER")
      ApiService.put(`/page/${pid()}/yeeko/${yeeko_id}/`, params)
        .then(({data}) => {
          commit('UPDATE_CURR_YEEKOS', ['update', data]);
          return resolve(data)
        })
        .catch(err => resolve(err))
    })
  },
  UPDATE_YKST_CIRCLES({commit, dispatch}, [yeeko_id, circles]) {
    let params = {circles: circles}
    return new Promise(resolve => {
      dispatch('SET_HEADER')
      ApiService.put(`/circles/add_circles_to_yeeko/${yeeko_id}/`, params)
        .then(({data}) => {
          return resolve(data)
        })
    })
  },
  POST_YEEKO({commit, dispatch}, yeeko) {
    let id = yeeko.id
    let post_url = id ? `${id}/` : ''
    yeeko.page_id = pid()
    return new Promise(resolve => {
      dispatch('SET_HEADER')
      ApiService[id ? 'put' : 'post'](`/page/${pid()}/yeeko/${post_url}`, yeeko)
        .then(({data}) => {
          console.log(data)
          if (id)
            commit('UPDATE_CURR_YEEKOS', ['update', data]);
          else
            commit('ADD_YEEKO', data);
          return resolve(data)
        })
    })
  },
  DELETE_YEEKO({commit, dispatch}, yeeko_id) {
    return new Promise(resolve => {
      dispatch("SET_HEADER")
      ApiService.delete(`/page/${pid()}/yeeko/${yeeko_id}/`)
        .then(({data}) => {
          commit('UPDATE_CURR_YEEKOS', ['delete', yeeko_id]);
          return resolve(data)
        })
        .catch(err => resolve(err))
    })
  },
  CREATE_PROPOSAL({commit, dispatch}, prop_data){
    return new Promise (resolve => {
      dispatch("SET_HEADER")
      ApiService.post("/proposal/", prop_data).then(({data})=>{
        commit("ADD_PROPOSAL", data)
        return resolve(data)
      })
    })
  },


};


export default {
  state,
  getters,
  actions,
  mutations,
}