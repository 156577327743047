<script>
import {mapState} from "vuex";
import * as d3 from "d3";
// import d3Parliament from "/src/
import d3Parliament from "./parliament.js";
import parties from "@/components/page/borde/parties";
import mixinLegend from "@/components/page/borde/mixinLegend";

export default {
  name: "PeriodViz",
  mixins: [mixinLegend],
  props: {
    measure_data: {
      type: Object,
      required: true
    },
    measure: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      logo_pac: require("@/assets/brand/logo_pac.png"),
      badges: {
        "affinity": require("@/assets/borde/affinity.png"),
        "borde_score": require("@/assets/borde/borde_score.png"),
        "quality": require("@/assets/borde/quality.png"),
      },
    };
  },
  computed: {
    ...mapState({
      chambers: state => state.brd.chambers,
      measures: state => state.brd.measures,
      political_parties: state => state.brd.political_parties,
    }),
    measure_full() {
      return this.measures.find(measure => measure.name === this.measure)
    },
    periods() {
      return this.measure_data.periods.map(period => {
        const start_year = period.start_date.split('-')[0]
        const end_year = period.end_date.split('-')[0]
        period.years = `${start_year}-${end_year}`
        return {
          chamber: this.chambers.find(chamber => chamber.id === period.chamber),
          id: period.id,
          name: period.name,
          years: period.years,
        }
      })
    },
    // final_positions: (vm) => vm.positions.filter(pos=>pos.name !== 'neutral'),
  },
  mounted() {
    // this.$nextTick(()=>{
    //   this.buildCharts()
    // })
  },
  methods: {
    buildVotes() {
      let data = this.measure_data
      return data.periods.map(period=>{
        period.chamber_obj = this.chambers.find(
            chamber=>chamber.id === period.chamber)
        let seats = period.seats.map(seat=>{
          const holder = seat.appointments.find(appointment=>
            appointment.is_holder)
          seat.party = holder.political_party
          const scores = data.scores[seat.id]
          return { ...seat, ...scores }
        })
        seats = seats.sort((a, b) => b[this.measure] - a[this.measure])
        let period_party = data.periods_party[period.id]
        if (!period_party)
          return period
        period.parties = data.political_parties.reduce((arr, party)=>{
          let party_measures = period_party[party.id] || {}
          let party_seats = seats.filter(seat=> seat.party === party.id)
          const obj = {
            ...party,
            ...party_measures,
            seats: party_seats,
          }
          arr.push(obj)
          return arr
        }, [])
        return period
      })
    },
    buildCharts(){
      let vm = this
      let margin = {top: 0, right: 20, bottom: 10, left: 20},
          width = 800,
          height = 675;
      // const white = 'rgba(255,255,255,0.66)'

      let all_periods = this.buildVotes()
      // console.log("all_periods", all_periods)
      const scores_list = Object.values(vm.measure_data.scores)
      // const parties_colors = vm.political_parties.reduce((obj, party)=>{
      //   obj[party.id] = party.color_viz
      //   return obj
      // }, {})

      let transparencyScale = d3.scaleLinear()
        .domain(d3.extent(scores_list, d=>d[vm.measure]))
        .range([0.2, 1])
        .clamp(true)

      all_periods.forEach(period=>{
        const is_dip = period.chamber_obj.name === 'Cámara de Diputados'
        // if (!is_dip){
        //   margin.top = 160
        // }
        // let votes = all_votes[chamber.id]
        let votes = period.parties

        console.log(`votes for ${period.name} -- ${vm.measure}\n`, votes)
        if (!votes)
          return
        votes = votes.filter(party=>party.seats.length > 0)
        let current_id = `#viz-${vm.measure}-${period.id}`

        // const is_empty = d3.select(`${current_id} > svg`).empty()
        // if (!is_empty)
        d3.select(`${current_id}`).selectAll("*").remove()
        let svg = d3.select(current_id)
          .append("svg")

        svg
          .attr("viewBox", [-margin.left, -margin.top,
              width + margin.right + margin.left,
              height + margin.bottom + margin.top])

        let legend_group = svg
          .append("g")
          .attr("transform", `translate(0, ${-margin.top + 5})`)

        let legend_ticks = svg
          .append("g")
          .attr("class", "legend_ticks")

        const parliament_margin = 280

        let parliament = d3Parliament()
          .width(width)
          .marginTop(parliament_margin)
          // .innerRadiusCoef(0.4)
          .innerRadiusCoef(is_dip ? 0.35 : 0.45)

        svg.datum(votes)
          .call(parliament)
          .selectAll('circle.seat')
          .attr('stroke', d => d.party.color_viz)
          .attr('stroke-width', is_dip ? 2 : 4)
          // .attr('stroke-opacity', .7)
          .attr('fill', d => d.party.color_viz)
          .attr('fill-opacity', d => {
            try {
              return transparencyScale(d.data[vm.measure])
            } catch (e) {
              console.log("error", e)
              console.log("d", d)
              return 0.5
            }
          })

        const width_party = width / votes.length
        parties(legend_group, votes, width_party, vm.measure)

        legend_group
          .selectAll('image.party_logo')
          .data(votes)
          .enter()
          .append('image')
          .attr('class', 'party_logo')
          .attr('xlink:href', this.badges[vm.measure])
          .attr('x', (d, i) => i * width_party + width_party / 2 - 48)
          .attr('y', 20)
          .attr('width', width_party + 8)


        legend_group
          .selectAll('text.measure')
          .data(votes)
          .enter()
          .append('text')
          .text(d => {
            try {
              return d[vm.measure].toFixed(0)
            } catch (e) {
              console.log("error", e)
              console.log("d", d)
              return '???'
            }
          })
          .attr('class', 'measure')
          .attr('x', (d, i) => i * width_party + width_party / 2)
          .attr('y', 27 + width_party / 2)
          .attr('font-size', 30)
          .attr('font-weight', 'bold')
          .attr('font-family', 'Montserrat')
          .attr('fill', d => d.color_viz)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .attr('dominant-baseline', 'middle')

        // const range_seq = d3.extent(scores_list, d=>d[vm.measure])
        const range_seq_ref = d3.extent(scores_list, d=>d[vm.measure] || 50)
        console.log("range_seq", vm.measure, range_seq_ref)
        const range_seq = [0, 100]
        let scale_color = d3.scaleSequential([-0.2, 1], d3.interpolateGreys)
        // let title = `Nivel de ${vm.measure_full.public_name}`
        let title = 'Nivel de Borde Score'
        const width_legend = 250
        const left_legend = 20

        vm.legend({
          color: d3.scaleSequential(range_seq, scale_color),
          title: title,
          tickSize: 5,
          fontSize: 11,
          fontFamiliy: 'Montserrat',
          marginLeft: (width - width_legend) / 2 + margin.left,
          marginRight: (width - width_legend) / 2,
          //marginLeft: vm.width - width_legend - left_legend,
          //marginTop: is_small ? 570 : 30,
          // marginTop: parliament_margin,
          marginTop: parliament_margin + 10,
          marginBottom: 0,
          height: parliament_margin + 30,
          ticks: 8,
          tickFormat: ".0f",
          width: width - left_legend,
          element_id: current_id,
        })

      })
    },
  },
}
</script>


<template>
  <div style="max-width: 500px">
    <v-row
      v-for="period in periods"
      :key="period.id"
    >
      <v-col
        cols="12"
        class="py-0"
      >
        <v-sheet
          color="#334050"
          class="pa-0 white--text text-center text-h6"
          style="font-family: 'Montserrat';"
        >
          <b>
            {{period.chamber.name.toUpperCase()}}
          </b>
          {{period.years}}
        </v-sheet>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-card tile elevation="4" class="pa-3">
          <div :id="`viz-${measure}-${period.id}`"></div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
</style>