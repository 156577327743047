<template>
  <v-parallax :src="background" height="340" class="py-5">
    <v-row class="my-1 my-1">
      <v-col cols="12" align="center" class="my-3">
        <img :width="170" :src="logo" alt="Logo de Yeeko" />
      </v-col>
      <v-col cols="12" align="center" class="text-h5">
        <div class="mb-1">
          Escucha e involucra
        </div>
        <div>
          a los miembros de tu organización
        </div>
      </v-col>
      <v-col cols="12" align="center">
        <v-btn 
          x-large color="white"
          class="primary--text" 
          to="/dashboard/login"
          elevation="10"
        >
          Comenzar
        </v-btn>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>
export default {
  name: "LandingHeader",
  data() {
    return {
      //background: require("@/assets/brand/background2.webp"),
      background: require("../../assets/brand/background.png"),
      logo: require("@/assets/brand/yeekowhite.webp")
    };
  }
};
</script>

<style lang="scss" scoped>
</style>