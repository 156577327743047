<template>
	<v-layout row wrap justify-center align-center id="footer">
		<v-flex xs12 lg4 pa-3>
			<ul class="text-sm-center text-md-left" id="links">
				<li>
          <router-link to="/login">COMENZAR A USARLO</router-link>
        </li>
				<li>
					<a href="https://web.yeeko.org/quienes_somos/">
						QUIÉNES SOMOS
					</a>
				</li>
				<!-- <li>CONTACTO</li> -->
				<li>
					<a target="_blank" href="https://m.me/yeekoapp?ref=:1255">
						CÓMO FUNCIONA
					</a>
				</li>
				<li><router-link to="/policies">AVISO DE PRIVACIDAD</router-link></li>
				<!-- <li>PREGUNTAS FRECUENTES</li> -->
			</ul>
		</v-flex>
		<v-flex xs12 lg4>
			Yeeko, Inteligencia Colectiva, SAPI de C.V.
			<br/>
			2020
		</v-flex>
		<v-flex xs12 lg4>
			<v-btn icon href="https://www.facebook.com/yeekoapp" target="_blank">
				<v-icon color="white">fab fa-facebook-f</v-icon>
			</v-btn>
			<v-btn icon href="https://twitter.com/yeekomx" target="_blank">
				<v-icon color="white" >fab fa-twitter</v-icon>
			</v-btn>
			<v-btn icon href="https://www.linkedin.com/company/yeeko/" target="_blank">
				<v-icon color="white" >fab fa-linkedin-in</v-icon>
			</v-btn>
			<v-spacer></v-spacer>
			<v-tooltip bottom>
				<template v-slot:activator="{ on }">
					<v-btn icon v-on="on" @click="$vuetify.goTo('#header')">
						<v-icon color="white">arrow_upward</v-icon>
					</v-btn>		
				</template>	
				<span>Ir al inicio</span>
			</v-tooltip>
		</v-flex>	

	</v-layout>
</template>
<script>
export default {
	name: "LandingFooter"
};
</script>
<style lang="scss" scoped>
#footer {
	background-color: #494949;
	color: white;
}
#links {
	list-style-type: none;
	li {
		a {
			text-decoration: none;
			color: white;
		}
		a:hover {
			color: #de006e;
		}
	}
}

	
</style>