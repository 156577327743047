<script>
import PeriodViz from "@/components/page/borde/PeriodViz.vue";
import {mapActions, mapState} from "vuex";
import ChamberViz from "@/components/page/borde/ChamberViz.vue";

export default {
  name: "InitiativeViz",
  components: {ChamberViz, PeriodViz},
  data() {
    return {
      full_initiative: null,
      parent_url: '',
      error_text: '',
      init_position:{
        name: 'all',
        alternative_name: 'all',
        public_name: 'all',
      },
    };
  },
  computed: {
    ...mapState({
      initiatives: state => state.brd.initiatives,
      // chambers: state => state.brd.chambers,
      positions: state => state.brd.positions,
    }),
    final_positions: (vm) => vm.positions.filter(pos=>pos.name !== 'neutral'),
  },
  mounted() {
    this.getParentUrl()
    this.fetch_borde_cats().then(()=>{
      this.retrieveInitiative()
    })
  },
  methods: {
    ...mapActions({
      fetch_borde_cats: 'GET_BORDE_CATS',
      getInitiative: 'GET_INITIATIVE',
    }),
    retrieveInitiative(){
      // console.log('$route', this.$route)
      let initiative_id = this.$route.params.initiative_id
      // const type_init_id = typeof initiative_id
      // if (typeof initiative_id === 'number'){
      const is_number = !isNaN(initiative_id)
      if (is_number)
        initiative_id = parseInt(initiative_id)
      else if (typeof initiative_id === 'string'){
        console.log("initiative_id", initiative_id)
        console.log("initiatives", this.initiatives)
        const initiative_obj = this.initiatives.find(init=>
          init.url_borde && init.url_borde.includes(initiative_id))
        if (initiative_obj)
          initiative_id = initiative_obj.id
        else
          this.error_text = "No se encontró la iniciativa"
      }
      else
        this.error_text = "No se encontró la iniciativa porque no es un formato válido"

      this.getInitiative(initiative_id).then(res=>{
        this.full_initiative = res
        this.redrawAllViz()
      })
    },
    redrawAllViz(){
      this.$nextTick(()=>{
        // console.log("this.$refs", this.$refs)
        // this.$refs.chamber_viz_all.forEach(viz=>
        //   viz.buildCharts())
        this.$refs.chamber_viz_all.buildCharts()
      })
    },
    getParentUrl() {
      try {
        if (window.parent && window.parent.location) {
          this.parent_url = window.parent.location.href;
        } else {
          console.warn('Parent window is not accessible.');
        }
      } catch (error) {
        console.error('Error accessing parent window URL:', error);
      }
    }

  },
}
</script>

<template>
  <v-alert
    v-if="error_text"
    type="error"
    dismissible
    border="left"
    elevation="2"
    icon="mdi-alert"
  >
    {{ error_text }}
  </v-alert>
  <v-card-text v-else-if="full_initiative">
    <ChamberViz
      ref="chamber_viz_all"
      :position="init_position"
      :initiative="full_initiative"
      :final_positions="final_positions"
    />
  </v-card-text>
</template>

<style scoped lang="scss">

</style>