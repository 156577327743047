<template>
  <div>
    <div id="loading-padding" v-if="redirecting">
      <h1>Cargando... Espera un momento por favor</h1>
      <v-progress-circular :size="50" color="primary" indeterminate>
      </v-progress-circular>
    </div>
    <router-view></router-view>
    <!--
    <div id="loading-padding" v-if="is_logging_in && $route.name !== 'Login'">
      <v-progress-circular :size="50" color="primary" indeterminate>
      </v-progress-circular>
    </div>
    <div v-else>
      <router-view :login_checked="!is_logging_in"></router-view>
    </div>-->
  </div>
</template>

<script>
import router from "@/router.js";
import { mapState } from "vuex";

export default {
  name: "WebviewHolder",
  data() {
    return {
      redirecting: true,
      text: "",
      url: ""
    }
  },
  computed: {
    ...mapState({
      status_logging_wv: state => state.auth.status_logging_wv,
      is_logging_wv: state => state.auth.is_logging_wv,
    }),
  },
  mounted(){
    // console.log(process.env.NODE_ENV)
    if (router.currentRoute.name != 'RedirectWebview'){
      this.redirecting = false;
      this.$store.dispatch("CHECK_PSID")
        .then((response)=>{
          console.log(response);
          if (!!response.psid){
            this.$store.dispatch("LOGIN_WEBVIEW", response.psid)
          }
        }).catch((err)=>{console.log(err)})
    }
  },
};
</script>

<style lang="scss" scoped>
.app-width {
  max-width: 800px;
}
#loading-padding {
  padding: 25px;
}
</style>