/*eslint-disable*/
import router from '@/router'
import axios from "axios";
import JwtService from "@/common/jwt.service";
import fileDownload from 'js-file-download';
import { API_URL } from "@/common/config";

let pid = (given_pid) => given_pid || router.currentRoute.params.pid

let ApiService = axios.create({
  baseURL: process.env.VUE_APP_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

const state = {
  total_participants: 0,
  new_invite: undefined,
};

const getters = {
};

const mutations = {
  SET_NEW_INVITE(state, invit=undefined){
    state.new_invite = invit
  },
};

const actions = {
  SET_HEADER({state}, is_download=false){
    let token = JwtService.getToken()
    if (token){
      //if (is_download)
      //DownloadService.defaults.headers.common['Authorization'] = `Token ${token}`;
      //else
      ApiService.defaults.headers.common['Authorization'] = `Token ${token}`;
    }
    //this.$axios.setToken(token, 'Token')
  },
  UPDATE_USER({commit, dispatch}, [user_id, params]) {
    return new Promise(resolve => {
      dispatch('SET_HEADER')
      ApiService.put(`/page/${pid()}/user/${user_id}/`, params)
        .then(({data}) => {
          return resolve(data)
        })
        .catch(({response})=>{
          console.log("error", response)
        })
    })
  },
  SAVE_INVITE({commit, dispatch}, [inv_data, inv_id]) {
    return new Promise(resolve => {
      dispatch('SET_HEADER')
      if (inv_id)
        ApiService.put(`/page/${pid()}/invitation/${inv_id}/`, inv_data)
          .then(({data}) => {
            console.log("guardado")
            return resolve(data)
          })
      else
        ApiService.post(`/page/${pid()}/invitation/`, inv_data)
          .then(({data}) => {
            commit('SET_NEW_INVITE', data)
            return resolve(data)
          })
    })
  },
  SEND_EMAIL_INVITE({commit, dispatch}, [inv_data, inv_id]) {
    return new Promise(resolve => {
      dispatch('SET_HEADER')
      ApiService.post(`/page/${pid()}/invitation/${inv_id}/send_email/`, inv_data)
        .then(({data}) => {
          return resolve(data)
        })
    })
  },
  DELETE_INVITATION({commit, dispatch}, inv_id) {
    return new Promise(resolve => {
      dispatch('SET_HEADER')
      ApiService.delete(`/page/${pid()}/invitation/${inv_id}/`)
        .then(({data}) => {
          //return resolve(data)
          fileDownload(res.data, filename);
        })
    })
  },
  DOWNLOAD_EXCEL2({commit, dispatch}, export_name) {
    //POST /api-rest/page/export_key
    return new Promise(resolve => {
      dispatch('SET_HEADER', true)
      DownloadService.get(`/page/${pid()}/${export_name}/`)
        .then(({data}) => {
          fileDownload(res.data, 'exportacion.xlsx');
          //return resolve(data)
        })
    })
  },
  DOWNLOAD_EXCEL({state, commit, dispatch}, export_name){
    return new Promise (resolve => {
      dispatch('SET_HEADER', true)
      ApiService.post(`/page/${pid()}/export_key/`).then(({data})=>{
        console.log(data)
        window.open(
          `${API_URL}/page/${pid()}/${export_name}/?key=${data.key}`,
          "_blank",
          ""
        );
        return resolve(data)
      })
    })
  },
  DOWNLOAD_EXCEL_NEW({state, commit, dispatch}, export_name){
    return new Promise (resolve => {
      dispatch('SET_HEADER', true)
      ApiService.post(`page/${pid()}/${export_name}/`).then(({data})=>{
        return resolve(data)
      })
    })
  },


};


export default {
  state,
  getters,
  actions,
  mutations,
}
